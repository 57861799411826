import fullAuth from "@/middlewares/fullAuth"

const Users = () => import(/* webpackChunkName: "clients" */ "../../components/pages/user/Users")
const UpdateUser = () => import(/* webpackChunkName: "clients" */ "../../components/pages/user/UpdateUser")

export default [
    {
        path: '/users',
        component: Users,
        name: 'users',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
            // permission: 'users.index'
        }
    },
    {
        path: '/users/:id',
        component: UpdateUser,
        name: 'edit-users',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/users/add',
        component: UpdateUser,
        name: 'add-users',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
]