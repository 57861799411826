import { decryptObject } from "@/core/helpers";

export default function fullAuth({ next, to }) {
	if (!window.localStorage.getItem("token")) {
		window.localStorage.removeItem("token");
		window.localStorage.removeItem("pinToken");
		window.localStorage.setItem("expectedRoute", JSON.stringify(to));

		next({ name: "login" });
		return;
	}

	if (
		window.localStorage.getItem("token") &&
		!window.localStorage.getItem("pinToken")
	) {
		window.localStorage.removeItem("pinToken");
		window.localStorage.setItem("expectedRoute", JSON.stringify(to));

		next({ name: "pin" });
		return;
	}

	let permissionsString = window.localStorage.getItem("permissions");

	// if (!permissionsString) {
	// 	window.toastr.error("No permissions string, reload page . . .");
	// }

	if (permissionsString) {
		let decrypted = decryptObject(permissionsString);
		if (decrypted && decrypted.length && to.meta.permission) {
			if (!decrypted.includes(to.meta.permission)) {
				window.toastr.error("NO PERMISSION!");
				return;
			}
		}
	}

	next();
	return;
}
