import fullAuth from "@/middlewares/fullAuth"

const Reservation = () => import(/* webpackChunkName: "promotions" */ "../../components/pages/reservation/Index")

export default [
    {
        path: '/reservation',
        component: Reservation,
        name: 'reservation',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/reservation/:date',
        component: Reservation,
        name: 'reservation-date',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]
