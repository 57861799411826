import fullAuth from "@/middlewares/fullAuth"

const Templates = () => import(/* webpackChunkName: "clients" */ "../../components/pages/Instruction")

export default [
    {
        path: '/instruction',
        component: Templates,
        name: 'instruction',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]