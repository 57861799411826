import fullAuth from "@/middlewares/fullAuth";

const CatalogCategory = () => import('../../components/menu/pages/Category')
const CatalogProduct = () => import('../../components/menu/pages/Product')

export default [
    {
        path: '/catalog/category/:categoryId',
        component: CatalogCategory,
        name: 'catalog-category',
        meta: {
            layout: 'MenuLandingLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/catalog/category/:categoryId/:productId',
        component: CatalogProduct,
        name: 'catalog-product',
        meta: {
            layout: 'MenuLandingLayout',
            middleware: [fullAuth],
        }
    },
]