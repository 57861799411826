import fullAuth from "@/middlewares/fullAuth";

const RouteIndex = () => import('../../components/pages/Index')
const MenuLanding = () => import('../../components/menu/pages/Landing')
const Dashboard = () => import('../../components/pages/Dashboard')
const Main = () => import('../../components/pages/Main')

export default [
    {
        path: '/',
        get name() {
            if (document.location.href.includes('allmenu.ge')) {
                return 'menu-landing-index'
            }

            return 'index'
        },
        get component() {
            if (document.location.href.includes('allmenu.ge')) {
                return MenuLanding
            }

            return RouteIndex
        },
        get meta() {
            if (document.location.href.includes('allmenu.ge')) {
                return {
                    layout: 'MenuLandingLayout'
                }
            }

            return {
                layout: 'PosLayout',
            }
        }
    },
    {
        path: '/dashboard',
        component: Dashboard,
        name: 'dashboard',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/main',
        component: Main,
        name: 'main',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    }
]