import fullAuth from "@/middlewares/fullAuth"

const Clients = () => import(/* webpackChunkName: "clients" */ "../../components/pages/Client/Clients")
const UpdateClient = () => import(/* webpackChunkName: "clients" */ "../../components/pages/Client/UpdateClient")

export default [
    {
        path: '/clients',
        component: Clients,
        name: 'clients',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/clients/:id',
        component: UpdateClient,
        name: 'edit-client',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/clients/add',
        component: UpdateClient,
        name: 'add-client',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
]