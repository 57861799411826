const MenuIndex = () => import('../../components/menu/pages/Index')

export default [
    {
        path: '/:companyKey',
        component: MenuIndex,
        name: 'menu-index',
        meta: {
            layout: 'MenuLayout'
        }
    },
    {
        path: '/:locale/:companyKey',
        component: MenuIndex,
        name: 'menu-index-locale',
        meta: {
            layout: 'MenuLayout'
        }
    },
    {
        path: '/:locale/:companyKey/:slug',
        component: MenuIndex,
        name: 'menu-category',
        meta: {
            layout: 'MenuLayout'
        }
    },
]