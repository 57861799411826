import fullAuth from "@/middlewares/fullAuth";

const Cashflow = () => import('../../components/pages/Cashflow')
const CreateCashflow = () => import('../../components/pages/cashflow/CreateCashflow')

export default [
    {
        path: '/cashflows',
        component: Cashflow,
        name: 'cashflows',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/create/cashflow',
        component: CreateCashflow,
        name: 'create-cashflow',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/cashflow/get/:cashflowId',
        component: CreateCashflow,
        name: 'get-cashflow',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
]