import fullAuth from "@/middlewares/fullAuth"

const Contracts = () => import(/* webpackChunkName: "user" */ "../../components/pages/user/contracts/Contracts.vue")

export default [
    {
        path: '/contracts',
        component: Contracts,
        name: 'contracts',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]
