export const siteRequisiteParameters = {
	keys: [
		"MANAGER_NAME",
		"COMPANY_NAME",
		"MONDAY_FROM",
		"MONDAY_TO",
		"MONDAY_BREAK_FROM",
		"MONDAY_BREAK_TO",
		"TUESDAY_FROM",
		"TUESDAY_TO",
		"TUESDAY_BREAK_FROM",
		"TUESDAY_BREAK_TO",
		"WENSDAY_FROM",
		"WENSDAY_TO",
		"WENSDAY_BREAK_FROM",
		"WENSDAY_BREAK_TO",
		"THURSDAY_FROM",
		"THURSDAY_TO",
		"THURSDAY_BREAK_FROM",
		"THURSDAY_BREAK_TO",
		"FRIDAY_FROM",
		"FRIDAY_TO",
		"FRIDAY_BREAK_FROM",
		"FRIDAY_BREAK_TO",
		"SATURDAY_FROM",
		"SATURDAY_TO",
		"SATURDAY_BREAK_FROM",
		"SATURDAY_BREAK_TO",
		"SUNDAY_FROM",
		"SUNDAY_TO",
		"SUNDAY_BREAK_FROM",
		"SUNDAY_BREAK_TO",
		// Phones
		"PHONE_1",
		"PHONE_2",
		"PHONE_3",
		"PHONE_4",
		"PHONE_5",
		"PHONE_WHATSAPP",
		"PHONE_VIBER",
		"PHONE_TELEGRAM",
		"PHONE_TELEGRAM_NAME",
		// Locations
		"LOC_REGION",
		"LOC_CITY",
		"LOC_METRO",
		"LOC_ADDRESS",
		"LOC_POST_INDEX",
		"LOC_GOOGLE_MAP_LINK",
		"LOC_YANDEX_MAP_LINK",
		"COORDINATE_YY",
		"COORDINATE_XX",
		"LOC_GOOGLE_MAP_EMBED",
		"LOC_YANDEX_MAP_EMBED",
		// Socials Facebook
		"SOC_FACEBOOK_LINK",
		"SOC_FACEBOOK_MESSENGER_ID",
		"SOC_FACEBOOK_COMMENTS_ID",
		"SOC_FACEBOOK_LOG",
		"SOC_FACEBOOK_PASS",
		"SOC_FACEBOOK_API_KEY",
		"SOC_FACEBOOK_MESSENGER_EMBED",
		"SOC_FACEBOOK_MESSENGER_TOKEN",
		"SOC_FACEBOOK_COMMENTS_EMBED",
		// Socials Instagram
		"SOC_INSTAGRAM_LINK",
		"SOC_INSTAGRAM_LOG",
		"SOC_INSTAGRAM_PASS",
		"SOC_INSTAGRAM_API_KEY",
		// Socials Vk
		"SOC_VKONTAKT_LINK",
		"SOC_VKONTAKT_LOG",
		"SOC_VKONTAKT_PASS",
		"SOC_VKONTAKT_API_KEY",
		// Socials Youtube
		"SOC_YOUTUBE_LINK",
		"SOC_YOUTUBE_LOG",
		"SOC_YOUTUBE_PASS",
		"SOC_YOUTUBE_API_KEY",
		// Socials Twitter
		"SOC_TWITTER_LINK",
		"SOC_TWITTER_LOG",
		"SOC_TWITTER_PASS",
		"SOC_TWITTER_API_KEY",
		"SOC_TWITTER_ID",
	],
};
