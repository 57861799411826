import auth from "@/middlewares/auth";

const Login = () => import('../../components/pages/authentication/Login')
const Pin = () => import('../../components/pages/authentication/Pin')
const Registration = () => import('../../components/pages/authentication/Registration')

export default [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            layout: 'PosLayout'
        }
    },
    {
        path: '/pin',
        component: Pin,
        name: 'pin',
        meta: {
            middleware: [auth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/registration',
        component: Registration,
        name: 'registration',
        meta: {
            layout: 'PosLayout'
        }
    },
]