<template>
	<metainfo>
		<template v-slot:title="{ content }">
			{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}
		</template>
	</metainfo>
	<div>
		<AppLayout>
			<router-view></router-view>
		</AppLayout>
	</div>
</template>
<script>
import AppLayout from "@/components/layouts/AppLayout";
import translations from "@/lang/core/translations";
import permissionTranslations from "@/lang/core/permission-translations";
import { useStore } from "vuex";

export default {
	name: "App",

	components: {
		AppLayout,
	},

	setup() {
        const store = useStore()

		store.commit("setTranslations", translations);
		store.commit("setPermissionTranslations", permissionTranslations);
	},
};
</script>
