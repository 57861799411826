import fullAuth from "@/middlewares/fullAuth"

const AboutUs = () => import(/* webpackChunkName: "videocontrol" */ "../../components/pages/AboutUs")

export default [
    {
        path: '/about-us',
        component: AboutUs,
        name: 'about-us',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]
