import fullAuth from "@/middlewares/fullAuth"
import {SearchTarget} from "@/core/enums"

const CreateOrder = () => import('../../components/pages/order/CreateOrder')
const SelectHall = () => import('../../components/pages/order/SelectHall')
const SelectTable = () => import('../../components/pages/order/SelectTable')
const Saleables = () => import('../../components/pages/order/Saleables')
const OrderDetails = () => import('../../components/pages/order/OrderDetails')

export default [
    {
        path: '/order/create',
        component: CreateOrder,
        name: 'create-order',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
            search: SearchTarget.CATEGORY_PRODUCT
        }
    },
    {
        path: '/order/create/select-hall',
        component: SelectHall,
        name: 'select-hall',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
            search: SearchTarget.CATEGORY_PRODUCT
        }
    },
    {
        path: '/order/create/select-table',
        component: SelectTable,
        name: 'select-table',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
            search: SearchTarget.CATEGORY_PRODUCT
        }
    },
    {
        path: '/order/create/saleables',
        component: Saleables,
        name: 'saleables',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
            search: SearchTarget.CATEGORY_PRODUCT
        }
    },
    {
        path: '/order/details/:id',
        component: OrderDetails,
        name: 'order-details',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
            search: SearchTarget.CATEGORY_PRODUCT
        }
    }
]