import fullAuth from "@/middlewares/fullAuth";


const Index = () => import('../../components/crm/Index')
const IncomeRequests = () => import('../../components/crm/income_requests/IncomeRequests')
const EmployeeTasks = () => import('../../components/crm/employee_tasks/EmployeeTasks.vue')
const Banks = () => import('../../components/crm/banks/Banks.vue')
const Instagram = () => import('../../components/crm/instagram/Instagram.vue')
const Pbx = () => import('../../components/crm/pbx/Pbx.vue')
const Chats = () => import('../../components/crm/chats/Chats.vue')


export default [
    {
        path: '/crm',
        component: Index,
        name: 'crm',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/crm/income-requests',
        component: IncomeRequests,
        name: 'income-requests',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/crm/employee-tasks',
        component: EmployeeTasks,
        name: 'employee-tasks',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/crm/banks',
        component: Banks,
        name: 'banks',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/crm/instagram',
        component: Instagram,
        name: 'instagram',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/crm/pbx',
        component: Pbx,
        name: 'pbx',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/crm/chats',
        component: Chats,
        name: 'chats',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
]