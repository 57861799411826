import fullAuth from "@/middlewares/fullAuth";

const Categories = () => import('../../components/pages/Categories')
const CarPartsCategories = () => import('../../components/pages/CarParts/CarPartsCategories')

export default [
    {
        path: '/categories',
        component: Categories,
        name: 'categories',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/categories/parts',
        component: CarPartsCategories,
        name: 'carPartsCategories',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    }
]