import fullAuth from "@/middlewares/fullAuth"

const Billing = () => import(/* webpackChunkName: "clients" */ "../../components/pages/billing/Billing")

export default [
    {
        path: '/billing',
        component: Billing,
        name: 'billing',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]