import fullAuth from "@/middlewares/fullAuth"

const Rs = () => import(/* webpackChunkName: "promotions" */ "../../components/pages/Rs")

export default [
    {
        path: '/rs',
        component: Rs,
        name: 'rs',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]
