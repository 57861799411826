import fullAuth from "@/middlewares/fullAuth";

const Hall = () => import('../../components/pages/order/Hall')
const Halls = () => import('../../components/pages/PosHalls')

export default [
    {
        path: '/hall/:id',
        component: Hall,
        name: 'hall',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/halls',
        component: Halls,
        name: 'halls',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
]