import fullAuth from "@/middlewares/fullAuth"

const Statistics = () => import('../../components/pages/stats/Statistics')

export default [
    {
        path: '/statistics',
        component: Statistics,
        name: 'statistics',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    }
]