// Styles
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/styles/variables.scss";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
	theme: {
		defaultTheme: "light",
	},
});
