<template>
	<div>
		<router-view />
	</div>
</template>

<script>
import "@/assets/styles/fonts.scss";
import "@/assets/styles/default.scss";

export default {
	name: "DefaultLayout",
};
</script>
