const ProductCreateTab = Object.freeze({
	ONE: "one",
	TWO: "two",
	THREE: "three",
	FOUR: "four",
	FIVE: "five",
	SIX: "six",
	Seven: "seven",
});

export default ProductCreateTab;
