import { cacheApiRequest } from "@/Http/Cache/Cache";
import { HttpMethod } from "@/core/enums";
import { useStore } from "vuex";
import _ from "lodash";
import { data_get } from "@/core/helpers";
import { siteRequisiteParameters } from "@/core/cacheRequestParameters";
import { userDetailsDefaultValue } from "@/core/defaultValues";

export const loadCache = (s) => {
	const store = s || useStore();

	const perPage = JSON.parse(window.localStorage.getItem("settings")).per_page;

	const parameterlessRequests = {
		setCompanyRequisites: "get/company/requisites",
		setBillingInvoices: "payment/invoices/get",
		setCatalogCategories: "get/catalog/categories",
		setCompanyAssets: "company/assets/get",
		// 'setRsInvoiceSeller': 'get/rs/seller/invoices',
		// 'setRsInvoiceBuyer': 'get/rs/buyer/invoices',
		setHalls: "halls/get",
		setTables: "tables/get/all",
		setLegalTypes: "legal/types/get",
		setSubscriptionData: "get/company/subscription/data",
		setCompanySubscriptionInvoices: "get/company/subscription/invoices",
		setUserWorkingSchedule: "working-schedule/get",
		setWorkAttendanceStats: "working-schedule/stats",
		setCarMarks: "car-parts/marks",
		setCarPartsCategories: "get/car-parts-categories",
	};

	const parameterlessRequestKeys = {
		setCompanyRequisites: "company_requisites-key",
		setBillingInvoices: "billing_invoices-key",
		setCatalogCategories: "catalog_categories-key",
		setCompanyAssets: "company_assets-key",
		// 'setRsInvoiceSeller': 'rs_seller_invoices-key',
		// 'setRsInvoiceBuyer': 'rs_buyer_invoices-key',
		setHalls: "halls-key",
		setTables: "tables-key",
		setLegalTypes: "legal-types-key",
		setSubscriptionData: "subscription-data-key",
		setCompanySubscriptionInvoices: "subscription-invoices-key",
		setUserWorkingSchedule: "working-schedule-key",
		setWorkAttendanceStats: "working-schedule-stats-key",
		setCarMarks: "car-marks-key",
		setCarPartsCategories: "car-parts-categories-key",
	};

	return new Promise((resolve) => {
		Object.keys(parameterlessRequests).forEach((key) => {
			cacheApiRequest(
				parameterlessRequests[key],
				{},
				parameterlessRequestKeys[key],
				{
					resolve: (response) => {
						if (!response.success) {
							return;
						}

						store.commit(key, response.data);
					},
					reject: null,
					method: HttpMethod.POST,
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		});

		cacheApiRequest("get/user/pos/settings", {}, "user_pos_settings-key", {
			resolve: (response) => {
				if (!response.success) {
					return;
				}

				if (data_get(response, "data.pos_settings.per_page")) {
					store.commit("setUserSettings", response.data.pos_settings);
				}
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest(
			"settings/get",
			{
				keys: [
					"VAT",
					"SERVICE_NAME",
					"CURRENCY",
					"LOCALE",
					"WOLT_DELIVERY_URL",
					"GLOVO_DELIVERY_URL",
					"ELVIS_DELIVERY_URL",
					"BOLT_DELIVERY_URL",
					"SITE_MAIN_COLOR",
					"MANAGER_NAME",
					"COMPANY_NAME",
					"CLOSING_TIME",
					"SHOW_TIME",
				],
			},
			"settings-key",
			{
				resolve: (response) => {
					if (!response.success) {
						return;
					}

					store.commit("setSettings", response.data);
					store.commit("setSettingsLoaded", true);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest(
			"get/site/requisites",
			siteRequisiteParameters,
			"site_requisites-key",
			{
				resolve: (response) => {
					if (!response.success) {
						return;
					}

					store.commit("setSiteRequisites", response.data);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest("stations/get", {}, "stations-key", {
			resolve: (response) => {
				store.commit("setStations", response.data);

				if (response.withoutStationProductsCount) {
					store.commit(
						"setWithoutStationProductsCount",
						response.withoutStationProductsCount
					);
				}
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("categories/products/get", {}, "categories-key", {
			resolve: (data) => {
				if (!data.data) {
					return;
				}

				data.data.forEach((category) => {
					category.products.forEach((product) => {
						store.commit("pushProducts", product);
					});
				});

				store.commit(
					"setCategoriesAndSaleables",
					_.filter(
						data.data,
						(item) => item.products_quantity > 0 || item.children.length > 0
					)
				);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest(
			"car-parts/categories/products/get",
			{},
			"car-parts-categories-key",
			{
				resolve: (data) => {
					if (!data.data) {
						return;
					}

					data.data.forEach((category) => {
						category.products.forEach((product) => {
							store.commit("pushProducts", product);
						});
					});

					store.commit(
						"setCarPartsCategoriesAndSaleables",
						_.filter(
							data.data,
							(item) => item.products_quantity > 0 || item.children.length > 0
						)
					);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest("get/branches", {}, "branches-key", {
			resolve: (response) => {
				store.commit("setBranches", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("clients/get", {}, "clients-key", {
			resolve: (response) => {
				store.commit("setClients", response.data);
				store.commit("setClientsTableList", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("promotions/get", {}, "clients-key", {
			resolve: (response) => {
				store.commit("setPromotions", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest(
			"supplies/get/all",
			{ page: 1, limit: perPage },
			"supplies-key",
			{
				resolve: (response) => {
					if (!response.success) {
						return;
					}

					store.commit("setSupplieData", response.data.data);
					store.commit("setSupplieLastPage", response.data.last_page);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest("employees/get/all", {}, "employees-key", {
			resolve: (response) => {
				if (!response.success) {
					return;
				}

				response.data.map((employee) => {
					employee.roles = employee.roleIds;
					employee.permissions = employee.permissionIds;

					employee.userDetails =
						employee.user_details || userDetailsDefaultValue;
				});

				store.commit("setEmployees", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("suppliers/get/all", {}, "suppliers-key", {
			resolve: (response) => {
				store.commit("setSuppliers", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("stockTakings/get/all", {}, "stockTakings-key", {
			resolve: (response) => {
				store.commit("setInventoriesData", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("storages/get/all", {}, "storages-key", {
			resolve: (response) => {
				store.commit("setStorages", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest(
			"last-month-supply-products",
			{},
			"last_month_supply_products-key",
			{
				resolve: (response) => {
					store.commit("setLastMonthSupplyProducts", response.data);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest("units/get/all", {}, "units-key", {
			resolve: (response) => {
				store.commit("setUnits", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("banks/get", {}, "banks-key", {
			resolve: (response) => {
				store.commit("setBanks", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("terminals/get", {}, "terminals-key", {
			resolve: (response) => {
				store.commit("setTerminals", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("cashflows/get", {}, "cashflows-key", {
			resolve: (response) => {
				if (!response.success) {
					return;
				}

				store.commit("setCashflows", response.data.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("equipment/get", {}, "equipment-key", {
			resolve: (response) => {
				store.commit("setEquipment", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("categories/get", {}, "all_categories-key", {
			resolve: (response) => {
				store.commit("setAllCategories", response.data);
				store.commit("setCategorySelectsTree", response.data);
				store.commit("changeCategoryLoader", false);
				store.commit("categoriesVerified");
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest(
			"ingredient/categories/get",
			{},
			"ingredient_categories-key",
			{
				resolve: (response) => {
					store.commit("setIngredientCategories", response.data);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest(
			"products/get/all",
			{ filters: null, page: 1, limit: perPage },
			"get_all_products-key",
			{
				resolve: (response) => {
					store.dispatch("setAllProductCommits", response.data);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest(
			"products/get",
			{ filters: null, page: 1, limit: perPage },
			"get_products_list-key",
			{
				resolve: (response) => {
					store.commit("changeProductsFetched", true);

					if (!response.data.data || !response.data.data.length) {
						store.commit("setProductsLength", 0);
						store.commit("setProductList", []);
						store.commit("changeProductsVerified", false);
						return;
					}

					store.commit("setProductsLength", response.data.total);
					store.commit("setProductList", response.data.data);
					store.commit("changeProductsVerified", true);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest(
			"car-parts/get",
			{ filters: null, page: 1, limit: perPage },
			"get_car_parts_list-key",
			{
				resolve: (response) => {
					store.commit("changeProductsFetched", true);

					if (!response.data.data || !response.data.data.length) {
						store.commit("setProductsLength", 0);
						store.commit("setProductList", []);
						store.commit("changeProductsVerified", false);
						return;
					}

					store.commit("setProductsLength", response.data.total);
					store.commit("setCarParts", response.data.data);
					store.commit("changeProductsVerified", true);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest(
			"ingredient/products/get",
			{},
			"get_ingredient_products-key",
			{
				resolve: (response) => {
					store.commit("setIngredientProducts", response.data);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest("list/counters", {}, "get_list_counters-key", {
			resolve: (response) => {
				store.commit("setListCounters", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest(
			"products/tree/get",
			{ filters: null, page: 1 },
			"get_products_tree_list-key",
			{
				resolve: (response) => {
					store.commit("setProductTreeList", response.data.data);
					store.commit("setProductTreeListLastPage", response.data.last_page);
				},
				reject: null,
				method: HttpMethod.POST,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		cacheApiRequest("get/permissions/base", {}, "base-permissions-key", {
			resolve: (response) => {
				store.commit(
					"setBasePermissions",
					data_get(response, "data.permissions", [])
				);
				store.commit("setBaseRoles", data_get(response, "data.roles", []));
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		cacheApiRequest("saleables/get", {}, "all-saleables-key", {
			resolve: (response) => {
				store.commit("setSaleables", response.data);
			},
			reject: null,
			method: HttpMethod.POST,
			headers: {
				"Content-Type": "application/json",
			},
		});

		resolve();
	});
};
