export default (dates) => async (to, from, next) => {
	const middleware = to.meta?.middleware || [];

	for (const method of middleware) {
		const result = await method({
			...dates,
			to,
			from,
			next,
		});

		if (result === false) {
			return;
		}
	}

	next();
	return;
};
