import fullAuth from "@/middlewares/fullAuth"

const usersSalaries = () => import(/* webpackChunkName: "user" */ "../../components/pages/user/usersSalaries/UsersSalaries.vue")

export default [
    {
        path: '/users-salaries',
        component: usersSalaries,
        name: 'users-salaries',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]
