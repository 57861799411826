import fullAuth from "@/middlewares/fullAuth"

const UsersWorkSchedule = () => import(/* webpackChunkName: "user" */ "../../components/pages/user/usersWorkSchedule/UsersWorkSchedule.vue")

export default [
    {
        path: '/users-work-schedule',
        component: UsersWorkSchedule,
        name: 'users-work-schedule',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]
