import fullAuth from "@/middlewares/fullAuth";

const Supply = () => import('../../components/pages/Supply')
const CreateSupply = () => import('../../components/pages/supply/CreateSupply')

const Suppliers = () => import('../../components/pages/supply/Suppliers')

export default [
    {
        path: '/supply',
        component: Supply,
        name: 'supply',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/supply/create',
        component: CreateSupply,
        name: 'create-supply',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/supply/create/:supplyId',
        component: CreateSupply,
        name: 'create-supply-stepper',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
    {
        path: '/supply/suppliers',
        component: Suppliers,
        name: 'suppliers',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout',
        }
    },
]
