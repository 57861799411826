import { computed } from "vue";
import { useRouter } from "vue-router";
import { loadCache } from "@/core/cacheLoader";

export const auth = () => {
	let localStorageToken = window.localStorage.getItem("token");
	let localStorageUser = window.localStorage.getItem("user");

	return (
		typeof localStorageToken !== typeof undefined &&
		localStorageToken !== null &&
		typeof localStorageUser !== typeof undefined &&
		localStorageUser !== null
	);
};

export const initApp = async (store) => {
	const router = useRouter();

	if (!store) {
		console.log("store not found");
		return;
	}

	const token = computed({
		get() {
			return store.getters.token;
		},
		set(value) {
			store.commit("setToken", value);
		},
	});

	const pinToken = computed({
		get() {
			return store.getters.pinToken;
		},
		set(value) {
			store.commit("setPinToken", value);
		},
	});

	const apiUrl = computed({
		get() {
			return store.getters.apiUrl;
		},
		set(value) {
			store.commit("setApiUrl", value);
		},
	});

	const user = computed({
		get() {
			return store.getters.user;
		},
		set(value) {
			store.commit("setUser", value);
		},
	});

	apiUrl.value = process.env.VUE_APP_API_URL;
	window.localStorage.setItem("apiUrl", process.env.VUE_APP_API_URL);
	const storageToken = window.localStorage.getItem("token");
	const storageUser = window.localStorage.getItem("user");
	const storagePinToken = window.localStorage.getItem("pinToken");
	const locale = window.localStorage.getItem("locale") || "ka";

	pinToken.value = storagePinToken;
	token.value = storageToken;
	user.value = JSON.parse(storageUser);

	window.localStorage.setItem("locale", locale);
	store.commit("setLocale", locale);

	if (auth()) {
		await loadCache(store);
		return;
	}

	if (!token.value || !user.value) {
		if (storageToken && storageUser) {
			await fetch(`${apiUrl.value}login/state`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: token.value,
					pinToken: storagePinToken,
				}),
			}).then((response) =>
				response.json().then((data) => {
					if (!data.success) {
						window.toastr.error(data.message || "Session expired");

						store.commit("setToken", null);
						store.commit("setUser", null);

						window.localStorage.removeItem("token");
						window.localStorage.removeItem("user");

						router.push("/login");
					}

					if (!data.data.pinValidity) {
						window.localStorage.removeItem("pinToken");
						store.commit("setPinToken", null);
						router.push({ name: "pin" });

						return;
					}

					window.localStorage.setItem("pinToken", data.data.pinToken);
					store.commit("setPinToken", data.data.pinToken);
				})
			);
			return;
		}

		window.localStorage.setItem("contentLoaded", true);

		await router.push({ name: "login" });
	}
};
