export default function auth({ next, to }) {
	if (!window.localStorage.getItem("token")) {
		window.localStorage.removeItem("token");
		window.localStorage.removeItem("pinToken");
		window.localStorage.setItem("expectedRoute", JSON.stringify(to));

		next({ name: "login" });
		return;
	}

	next();
	return;
}
