<template>
	<component :is="layout">
		<slot />
	</component>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout";
import { markRaw } from "vue";

export default {
	name: "AppLayout",

	data: () => ({
		layout: markRaw(DefaultLayout),
	}),

	watch: {
		$route: {
			immediate: true,
			async handler(route) {
				if (!route.meta.layout) return;

				try {
					const component = await import(
						`@/components/layouts/${route.meta.layout}.vue`
					);
					this.layout = markRaw(component?.default);
				} catch (e) {
					this.layout = markRaw(DefaultLayout);
				}
			},
		},
	},
};
</script>
