import fullAuth from "@/middlewares/fullAuth";

const StationDashboard = () => import('../../components/pages/Station/Dashboard')

export default [
    {
        path: '/stations',
        component: StationDashboard,
        name: 'stations',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    }
]