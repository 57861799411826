// import { HttpMethod } from "@/core/enums";
// import AuthorisationException from "@/Exceptions/AuthorisationException";
// import _ from "lodash";

// export default class ApiRequest {
// 	constructor(
// 		url,
// 		body,
// 		resolve = null,
// 		reject = null,
// 		method = HttpMethod.POST,
// 		headers = { "Content-Type": "application/json" },
// 		cacheResolve = null,
// 		cacheReject = null
// 	) {
// 		this.baseUrl = window.localStorage.getItem("apiUrl");

// 		if (headers["Content-Language"] === undefined) {
// 			headers["Content-Language"] = window.localStorage.getItem("locale");
// 		}

// 		this.url = this.baseUrl ? this.baseUrl + url : url;
// 		this.method = method;
// 		this.headers = headers;
// 		this.body = body;
// 		this.resolve =
// 			resolve !== null
// 				? resolve
// 				: (data) => {
// 						if (!data.success) {
// 							// window.toastr.error(data.message || 'Something went wrong')
// 						}
// 				};
// 		this.reject = reject !== null ? reject : () => {};
// 		this.cacheResolve = cacheResolve !== null ? cacheResolve : () => {};

// 		this.cacheReject = cacheReject !== null ? cacheReject : () => {};

// 		if (this.auth) {
// 			let token = window.localStorage.getItem("token");
// 			let pinToken = window.localStorage.getItem("pinToken");

// 			if (typeof token === typeof undefined) {
// 				throw new AuthorisationException("No token found");
// 			}

// 			let hiddenUrls = [];
// 			ApiRequest.hiddenRequestsForPinTokenValidation.forEach((url) =>
// 				hiddenUrls.push(this.baseUrl ? this.baseUrl + url : url)
// 			);

// 			if (
// 				!hiddenUrls.includes(this.url) &&
// 				(typeof pinToken === typeof undefined ||
// 					pinToken === "undefined" ||
// 					pinToken === null)
// 			) {
// 				if (
// 					!_.filter(ApiRequest.requestQueue, (req) => req.url === this.url)
// 						.length
// 				) {
// 					ApiRequest.requestQueue.push(this);
// 				}

// 				return;
// 			}

// 			this.body.token = token;
// 			this.body.pinToken = pinToken;
// 		}

// 		this.sendRequest();
// 	}

// 	static get inProgress() {
// 		return ApiRequest.progressCount > 0;
// 	}

// 	static get inProgressCount() {
// 		return ApiRequest.progressCount;
// 	}

// 	static set inProgressCount(value) {
// 		ApiRequest.progressCount = value;
// 		ApiRequest.inProgressCountChange();
// 	}

// 	get auth() {
// 		let token = window.localStorage.getItem("token");
// 		let user = window.localStorage.getItem("user");

// 		return (
// 			typeof token !== typeof undefined &&
// 			token !== null &&
// 			typeof user !== typeof undefined &&
// 			user !== null
// 		);
// 	}

// 	increaseProgressCount() {
// 		if (!ApiRequest.hiddenRequestsForProgressCount.includes(this.url)) {
// 			ApiRequest.inProgressCount++;
// 		}
// 	}

// 	decreaseProgressCount() {
// 		if (!ApiRequest.hiddenRequestsForProgressCount.includes(this.url)) {
// 			ApiRequest.inProgressCount--;
// 		}
// 	}

// 	sendRequest() {
// 		this.increaseProgressCount();
// 		fetch(this.url, {
// 			method: this.method,
// 			headers: this.headers,
// 			body: JSON.stringify(this.body),
// 		})
// 			.then((response) =>
// 				response.json().then((data) => {
// 					this.cacheResolve(data);
// 					this.resolve(data);
// 				})
// 			)
// 			.catch((error) => {
// 				console.error(error);
// 				this.cacheResolve(error);
// 				this.reject(error);
// 			})
// 			.finally(() => {
// 				this.decreaseProgressCount();

// 				if (ApiRequest.requestQueue.length) {
// 					let request = ApiRequest.requestQueue.shift();
// 					request.body.token = window.localStorage.getItem("token");
// 					request.body.pinToken = window.localStorage.getItem("pinToken");
// 					request.sendRequest();
// 				}
// 			});
// 	}
// }

// ApiRequest.progressCount = 0;
// ApiRequest.inProgressCountChange = () => {};
// ApiRequest.hiddenRequestsForProgressCount = [];
// ApiRequest.hiddenRequestsForPinTokenValidation = [
// 	"login",
// 	"login/pin",
// 	"login/state",
// 	"menu/company/get",
// 	"menu/company/categories/products/get",
// 	"menu/company/requisites/get",
// 	"menu/categories/get",
// ];
// ApiRequest.requestQueue = [];

import { HttpMethod } from "@/core/enums";
import AuthorisationException from "@/Exceptions/AuthorisationException";

export default class ApiRequest {
	constructor(
		url,
		body,
		resolve = (data) => {
			if (!data.success) {
				// window.toastr.error(data.message || 'Something went wrong')
			}
		},
		reject = () => {},
		method = HttpMethod.POST,
		headers = { "Content-Type": "application/json" },
		cacheResolve = () => {},
		cacheReject = () => {}
	) {
		const baseUrl = window.localStorage.getItem("apiUrl");
		headers["Content-Language"] =
			headers["Content-Language"] ?? window.localStorage.getItem("locale");

		this.url = baseUrl ? `${baseUrl}${url}` : url;
		this.method = method;
		this.headers = headers;
		this.body = body;
		this.resolve = resolve;
		this.reject = reject;
		this.cacheResolve = cacheResolve;
		this.cacheReject = cacheReject;

		if (this.auth) {
			const token = window.localStorage.getItem("token");
			const pinToken = window.localStorage.getItem("pinToken");

			if (!token) {
				throw new AuthorisationException("No token found");
			}

			const hiddenUrls = ApiRequest.hiddenRequestsForPinTokenValidation.map(
				(url) => (baseUrl ? `${baseUrl}${url}` : url)
			);

			if (!hiddenUrls.includes(this.url) && !pinToken) {
				if (!ApiRequest.requestQueue.some((req) => req.url === this.url)) {
					ApiRequest.requestQueue.push(this);
				}

				return;
			}

			this.body.token = token;
			this.body.pinToken = pinToken;
		}

		this.sendRequest();
	}

	static get inProgress() {
		return ApiRequest.progressCount > 0;
	}

	static get inProgressCount() {
		return ApiRequest.progressCount;
	}

	static set inProgressCount(value) {
		ApiRequest.progressCount = value;
		ApiRequest.inProgressCountChange();
	}

	get auth() {
		let token = window.localStorage.getItem("token");
		let user = window.localStorage.getItem("user");

		return (
			typeof token !== typeof undefined &&
			token !== null &&
			typeof user !== typeof undefined &&
			user !== null
		);
	}

	increaseProgressCount() {
		if (!ApiRequest.hiddenRequestsForProgressCount.includes(this.url)) {
			ApiRequest.inProgressCount++;
		}
	}

	decreaseProgressCount() {
		if (!ApiRequest.hiddenRequestsForProgressCount.includes(this.url)) {
			ApiRequest.inProgressCount--;
		}
	}

	sendRequest() {
		this.increaseProgressCount();
		fetch(this.url, {
			method: this.method,
			headers: this.headers,
			body: JSON.stringify(this.body),
		})
			.then((response) =>
				response.json().then((data) => {
					this.cacheResolve(data);
					this.resolve(data);
				})
			)
			.catch((error) => {
				console.error(error);
				this.cacheResolve(error);
				this.reject(error);
			})
			.finally(() => {
				this.decreaseProgressCount();

				if (ApiRequest.requestQueue.length) {
					let request = ApiRequest.requestQueue.shift();
					request.body.token = window.localStorage.getItem("token");
					request.body.pinToken = window.localStorage.getItem("pinToken");
					request.sendRequest();
				}
			});
	}
}
ApiRequest.progressCount = 0;
ApiRequest.inProgressCountChange = () => {};
ApiRequest.hiddenRequestsForProgressCount = [];
ApiRequest.hiddenRequestsForPinTokenValidation = [
	"login",
	"login/pin",
	"login/state",
	"menu/company/get",
	"menu/company/categories/products/get",
	"menu/company/requisites/get",
	"menu/categories/get",
];
ApiRequest.requestQueue = [];
