import ApiRequest from "@/Http/ApiRequest";
import {
	DiscountValueType,
	OrderDeliveryType,
	PaymentCalculatorType,
	ReservationStatus,
} from "@/core/enums";
import { initApp } from "@/core/base";
import _ from "lodash";
import { data_get } from "@/core/helpers";
import { ProductCreateTab } from "@/core/enums";

export default {
	setInternetConnected({ commit }, internetConnected) {
		commit("setInternetConnected", internetConnected);
	},

	initApp({ commit }, store) {
		initApp(store).then(() => {
			if (window.localStorage.getItem("token")) {
				store.dispatch("fetchCounters");
			}
		});

		commit("setInternetConnected", true);
	},

	fetchCounters({ commit }) {
		new ApiRequest("counters/get", {}, (data) => {
			if (!data.success) {
				return;
			}

			commit("setCounters", data.data);
		});
	},

	syncOrderSaleables(store, orderSaleablesData) {
		const posOrderId = orderSaleablesData.orderId;
		const products = orderSaleablesData.products;

		const company = store.getters.company;

		const translations = company.translations;
		const translation = translations.find(
			(translation) => translation.locale === "en"
		);

		if (!translation) return false;

		const isCarPartsPlatform =
			(translation.object_type || "").toLowerCase().replace(" ", "_") ===
			"car_parts";

		new ApiRequest(
			"order/update",
			{
				posOrderId,
				products,
				isCarPartsPlatform,
			},
			(data) => {
				if (!data.success) {
					window.toastr.error(data.message ?? "Something went wrong");
					return;
				}

				store.commit("setSaleablesToOrder", {
					orderId: data.data.order.id,
					saleables: data.data.saleables,
				});
				store.commit("setOrder", data.data.order);
			}
		);
	},

	removeOrderDataFromState({ commit }) {
		commit("setOrderHall", null);
		commit("setOrderTable", null);
		commit("setOrderSaleables", []);
		commit("setOrder", null);
		commit("setOrderPaymentCardAmount", null);
		commit("setOrderPaymentCashAmount", null);
		commit("setOrderPaymentChangeAmount", null);
		commit("setOrderPaymentTipAmount", null);
		commit("setOrderPaymentCalculatorType", PaymentCalculatorType.CASH);
		commit("setOrderClient", null);
		commit("setOrderPromotion", null);
		commit("setOrderPersonsCount", null);
		commit("setOrderDiscount", {
			type: null,
			typeId: null,
			value: 0,
			valueType: DiscountValueType.PERCENT,
		});
		commit("setOrderDeliveryType", OrderDeliveryType.HALL);
	},

	getOrder({ commit }, orderId) {
		new ApiRequest("order/get", { orderId }, (data) => {
			if (!data.success) {
				window.toastr.error(data.message || "Order not found");
				return;
			}

			commit("setOrder", data.data.order);
			commit("setOrderSaleables", data.data.order.products);
		});
	},

	addSaleableToOrder(context, saleable) {
		// context.commit('unshiftOrderSaleable', {
		//     ...saleable,
		//     addedToOrderAt: Date.now(),
		// })

		context.commit("unshiftOrderSaleable", saleable);

		let productIds = [];

		for (const saleable of context.getters.orderSaleables) {
			productIds.push(saleable.id);
		}

		if (context.getters.order) {
			context.dispatch("syncOrderSaleables", {
				orderId: context.getters.order.id,
				products: productIds,
			});
		}
	},

	resetSupplyCreateForm({ commit }) {
		commit("setDefaultSupplySteps");
		commit("setSupplyTab", "tab-1");
		commit("resetSupplyProductsData");
		commit("setDefaultToSupplyDisabledSteps");
		commit("setDefaultProductFiltersInSupplyCreate");

		commit("setSupplyData", {
			type: "order",
			date: new Date()
				.toLocaleString("sv-SE", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
					second: "2-digit",
				})
				.replace(" ", "T"),
			supplier_id: null,
			storage_id: null,
			employee_id: null,
			cashier_id: null,
			receive_employee_id: null,
			thirdStepProducts: [],
		});

		commit("setSupplyThirdStepProducts", []);

		commit("setSupplyEvaluated", false);

		commit("setSupplyCashflow", []);
	},

	setAllProductCommits(context, data) {
		if (!data) {
			return;
		}

		let productCachedList = data.filter((product) => {
			return product.rs_ge === false;
		});

		productCachedList = _.orderBy(productCachedList, ["name"]);

		// productCachedList = _.take(
		// 	productCachedList,
		// 	parseInt(context.getters.userSettings.per_page)
		// );

		context.commit("setProductCachedList", productCachedList);

		let productTableList = data;

		productTableList = productTableList.filter((product) => {
			return product.rs_ge === false;
		});

		productTableList = _.orderBy(productTableList, ["name"]);

		let lastPage = Math.round(
			parseInt(productTableList.length) /
				parseInt(context.getters.userSettings.per_page)
		);

		context.commit("setProductListLastPage", lastPage);

		context.commit("setAllProductsForProductsTable", productTableList);
		context.commit("setAllProducts", data);
	},

	getCatalogProducts({ commit }) {
		new ApiRequest("products/catalog/all", {}, (data) => {
			if (!data.success) {
				window.toastr.error(data.message || "Something went wrong");
				return;
			}

			commit("setAllCatalogProductsForProductsTable", data.data);
		});
	},

	logout({ commit }) {
		commit("setUser", null);
		commit("setToken", null);
		commit("setPinToken", null);
	},

	clearReservationData({ commit }) {
		commit("setReservationData", {
			status: ReservationStatus.PENDING,
			reservationDate: null,
			reservationTime: null,
			personsCount: null,
			name: null,
			email: null,
			hall: null,
			table: null,
			phoneNumber: null,
			comment: null,
			clientId: null,
			date: null,
			cameFrom: null,
			social: null,
		});

		commit("setReservationEditMode", false);
		commit("setTimePicker", {
			hh: null,
			mm: null,
		});
	},

	resetReservationFilters({ commit }) {
		commit("setReservationFilters", {
			status: null,
			client: null,
			hall: null,
			table: null,
			personsCount: null,
			from: null,
			to: null,
		});
	},

	resetTimePicker({ commit }) {
		commit("setTimePicker", {
			hh: null,
			mm: null,
		});
	},

	resetPromotionData({ commit }) {
		commit("setPromotionData", {
			name: null,
			discount: null,
			from: null,
			to: null,
			clients: [],
			employees: [],
			id: null,
		});
	},

	resetHighlightedOrderId({ commit }) {
		commit("setHighlightedOrderId", null);
	},

	resetCategoryData({ commit }) {
		commit("setCategoryData", {
			en: {
				name: null,
				short_name: null,
				description: null,
				comment: null,
			},
			ka: {
				name: null,
				short_name: null,
				description: null,
				comment: null,
			},
			ru: {
				name: null,
				short_name: null,
				description: null,
				comment: null,
			},
			icon: null,
			parent_id: null,
			ordering: null,
			is_catalog_category: false,
			ingredient_category: false,
			properties: {
				link_site: null,
				link_facebook: null,
				link_instagram: null,
				link_other: null,
			},
			images: [],
			links: {
				site: null,
				facebook: null,
				instagram: null,
				other: null,
			},
		});
	},

	setCompanySubscription(context, data) {
		let companyData = context.getters.company;

		companyData.is_payment_active = data_get(data, "is_payment_active", false);
		companyData.trial_ends_at = data_get(data, "trial_ends_at", null);
		companyData.subscription_status = data_get(
			data,
			"subscription_status",
			"ACTIVE"
		);
		companyData.subscription_comment = data_get(
			data,
			"subscription_comment",
			null
		);
		companyData.subscription_plan_price = data_get(
			data,
			"subscription_plan_price",
			null
		);

		context.commit("setCompany", companyData);
	},

	resetSettingsTab({ commit }) {
		commit("setSettingsTab", null);
	},

	resetBillingData({ commit }) {
		commit("setBillingData", {
			months: null,
			paymentType: null,
			rememberCard: false,
		});
	},

	resetSubscriptionData({ commit }) {
		commit("setSubscriptionData", {
			subscriptionAddons: [],
			subscriptionPrice: null,
		});
	},

	resetUserAddWorkingScheduleData({ commit }) {
		commit("setUserAddWorkingScheduleData", {
			employeeId: null,
			comment: null,
			dates: [],
			hours: null,
		});
	},

	resetUsersWorkScheduleFilters({ commit }) {
		commit("setUsersWorkScheduleFilters", {
			keyword: null,
			employees: [],
			from: null,
			to: null,
		});
	},

	resetWorkAttendance({ commit }) {
		commit("setWorkAttendance", {
			startAt: null,
			endAt: null,
		});
	},

	resetCarPartsCategoryData({ commit }) {
		commit("setCarPartsCategoryData", {
			en: {
				name: null,
				// short_name: null,
				description: null,
				comment: null,
			},
			ka: {
				name: null,
				// short_name: null,
				description: null,
				comment: null,
			},
			ru: {
				name: null,
				// short_name: null,
				description: null,
				comment: null,
			},
			icon: null,
			parent_id: null,
			ordering: null,
			is_catalog_category: false,
			modificationId: null,
			modelId: null,
			markId: null,
			properties: {
				link_site: null,
				link_facebook: null,
				link_instagram: null,
				link_other: null,
			},
			images: [],
			links: {
				site: null,
				facebook: null,
				instagram: null,
				other: null,
			},
		});
	},

	resetSupplierData({ commit }) {
		commit("setSupplierData", {
			name: null,
			email: null,
			identity: null,
			legalType: null,
			phone: null,
			address: null,
			comment: null,
		});
	},

	resetSupplierDeleteDialog({ commit }) {
		commit("setSupplierIdToDelete", null);
		commit("setShowDeleteSupplierDialog", false);
	},

	assignCarPart({ commit }, data) {
		data.ka = {
			comment: null,
			name: null,
		};

		data.en = {
			comment: null,
			name: null,
		};

		data.ru = {
			comment: null,
			name: null,
		};

		if (data.translations && data.translations.length) {
			data.translations.forEach((translation) => {
				switch (translation.locale) {
					case "ka":
						data.ka.comment = translation.comment;
						data.ka.name = translation.name;
						break;
					case "en":
						data.en.comment = translation.comment;
						data.en.name = translation.name;
						break;
					case "ru":
						data.ru.comment = translation.comment;
						data.ru.name = translation.name;
						break;
				}
			});
		}

		let categories = data.categories || [];
		data.categories = [];

		categories.forEach((category) => {
			data.categories.push(category.id);
		});

		let images = data.images || [];
		data.images = [];

		images.forEach((image) => {
			if (image.image_type === "MEDIUM") {
				data.images.push({
					imageId: image.id,
					source: image.url,
					options: {
						type: "local",
						metadata: {
							poster: image.url,
						},
					},
				});
			}
		});

		data.imageCrop = true;

		let createData = {
			product_type: 10,
			quantity: data_get(data, "quantity"),
			is_active: data_get(data, "is_active") || false,
			supportedCars: [],
			price: data_get(data, "price"),
			price_discount: data_get(data, "price_discount"),
			priceCost: data_get(data, "price_cost"),
			code: data_get(data, "code"),
			condition: data_get(data, "condition") || "NEW",
			is_original: data_get(data, "is_original") || false,
			categories: data_get(data, "categories"),
			properties: {
				link_site: data_get(data, "properties.link_site"),
				link_facebook: data_get(data, "properties.link_facebook"),
				link_instagram: data_get(data, "properties.link_instagram"),
				link_other: data_get(data, "properties.link_other"),
			},
			images: images,
			meta: data_get(data, "meta") || {},
			ka: data_get(data, "ka"),
			en: data_get(data, "en"),
			ru: data_get(data, "ru"),
		};

		let selectOptionsData = data.selectOptions || [];

		if (!selectOptionsData.length) {
			selectOptionsData.push({
				mark: null,
				model: null,
				modification: null,
				generation: null,
				selectOptions: {
					models: [],
					modifications: [],
					generations: [],
				},
			});
		}

		selectOptionsData.forEach((selectOption) => {
			let supportedCar = {
				mark: selectOption.mark_id,
				model: selectOption.model_id,
				generation: selectOption.generation_id,
				modification: selectOption.modification_id,
				selectOptions: {
					models: data_get(selectOption, "selectOptions.models") || [],
					modifications:
						data_get(selectOption, "selectOptions.modifications") || [],
					generations:
						data_get(selectOption, "selectOptions.generations") || [],
				},
			};

			createData.supportedCars.push(supportedCar);
		});

		createData.supportedCars = selectOptionsData;

		commit("setCarPartCreateData", createData);
	},

	checkWorkingSchedule({ commit }) {
		new ApiRequest("working-schedule/check", {}, (response) => {
			if (!response.success) {
				return;
			}

			if (response.attendanceRules && response.attendanceRules.length > 0) {
				commit("setCurrentAttendanceRule", response.attendanceRules[0]);
			}

			if (
				response.upcomingAttendanceHoursForToday &&
				response.upcomingAttendanceHoursForToday.length > 0
			) {
				commit(
					"setUpcomingAttendanceHoursForToday",
					response.upcomingAttendanceHoursForToday
				);
			}

			if (
				response.attendanceForYesterday &&
				response.attendanceForYesterday.length > 0
			) {
				commit("setAttendanceForYesterday", response.attendanceForYesterday);
			}

			if (
				response.attendanceForTomorrow &&
				response.attendanceForTomorrow.length > 0
			) {
				commit("setAttendanceForTomorrow", response.attendanceForTomorrow);
			}

			if (
				response.attendanceForToday &&
				response.attendanceForToday.length > 0
			) {
				commit("setAttendanceForToday", response.attendanceForToday);
			}

			if (response.data) {
				commit("setWorkAttendance", {
					startAt: data_get(response, "data.start_at"),
					endAt: data_get(response, "data.end_at"),
				});
			}
		});
	},

	setIncomeRequestsView({ state }, value) {
		state.incomeRequestsView = value;
	},

	openProductCanvas({ commit }) {
		commit("setProductMode", false);
		commit("setProductDisabledMode", false);
		commit("resetProductCreateData");
		commit("setProductCreateTab", ProductCreateTab.ONE);
		commit("setProductCanvasCondition", true);
	},
};
