export default Object.freeze({
	ORDER_SENT_TO_STATIONS: "sendPosOrderSentToStationsNotification",
	UPDATE_PRODUCT: "updatePosProduct",
	PURGE_PRODUCT: "purgePosProduct",
	UPDATE_TREE: "updatePosTree",
	ADD_PRODUCT: "addPosProduct",
	CATEGORY_TREE: "categoryTree",
	REGENERATION_INGREDIENT_CATEGORIES: "regenerationIngredientCategories",
	CREATE_ORDER: "createPosOrder",
	UPDATE_ORDER: "updatePosOrder",
	CLOSE_ORDER: "deletePosOrder",
	PAY_ORDER: "sendPosOrderPayNotification",
	UPDATE_SITE_SETTINGS: "updateSiteSettings",
	ADD_RESERVATION: "sendReservationRequestNotification",
	UPDATE_RESERVATION: "sendReservationRequestUpdateNotification",
	UPDATE_PROMOTIONS: "updatePromotions",
	UPDATE_PROMOTION: "updatePromotion",
	CREATE_PROMOTION: "createPromotion",
	COMPANY_SUBSCRIPTION_UPDATED: "updateCompanySubscription",
	COMPANY_EMPLOYEE_UPDATED: "updateEmployeeInEmployees",
	WORKING_SCHEDULE_UPDATED: "updateWorkingSchedule",
	UPDATE_CAR_PART: "updatePosCarPart",
	ADD_CAR_PART: "addPosCarPart",
	PURGE_CAR_PART: "purgePosCarPart",
});
