export default function (store) {
	return {
		permission: {
			mounted(el, binding) {
				if (
					!store.getters.userPermissions.find(
						(permission) => permission.name === binding.value
					)
				) {
					el.style.display = "none";
				}
			},
		},
		role: {
			mounted(el, binding) {
				if (
					!store.getters.userRoles.find(
						(permission) => permission.name === binding.value
					)
				) {
					el.style.display = "none";
				}
			},
		},
		"focus-cycle": {
			mounted(el) {
				const tabbableElements = el.parentNode.querySelectorAll("[tabindex]");
				const firstTabbableElement =
					el.parentNode.querySelector('[tabindex="1"]');

				el.addEventListener("keydown", function (event) {
					if (event.key === "Tab") {
						const focusedElement = document.activeElement;
						const focusedIndex =
							Array.from(tabbableElements).indexOf(focusedElement);

						if (focusedIndex === tabbableElements.length - 1) {
							event.preventDefault();
							firstTabbableElement.focus();
						}
					}
				});
			},
		},
	};
}
