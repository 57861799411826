const NotFound = () => import('../../components/pages/NotFound')

export default [
    {
        path: '/404',
        component: NotFound,
        name: 'not-found-404',
        meta: {
            layout: 'DefaultLayout'
        }
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound,
        name: 'not-found'
    },
]