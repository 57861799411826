import fullAuth from "@/middlewares/fullAuth"

const Permissions = () => import(/* webpackChunkName: "clients" */ "../../components/pages/permissions/Permissions")

export default [
    {
        path: '/permissions',
        component: Permissions,
        name: 'permissions',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
]