import fullAuth from "@/middlewares/fullAuth";

const Settings = () => import('../../components/pages/Settings')

export default [
    {
        path: '/settings',
        component: Settings,
        name: 'settings',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
]