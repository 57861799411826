import { kebabCase } from "./utils";
import { getCurrentInstance } from "vue";

export const isArray = (value) => {
	return Array.isArray(value);
};

export const data_get = (target, path, fallback) => {
	if (!target) {
		return fallback;
	}

	let segments = isArray(path) ? path : path.split(".");
	let [segment] = segments;

	let find = target;

	if (segment !== "*" && segments.length > 0) {
		if (find[segment] === null || typeof find[segment] === "undefined") {
			find = typeof fallback === "function" ? fallback() : fallback;
		} else {
			find = data_get(find[segment], segments.slice(1), fallback);
		}
	} else if (segment === "*") {
		const partial = segments.slice(path.indexOf("*") + 1, path.length);

		if (typeof find === "object") {
			find = Object.keys(find).reduce(
				(build, property) => ({
					...build,
					[property]: data_get(find[property], partial, fallback),
				}),
				{}
			);
		} else {
			find = data_get(find, partial, fallback);
		}
	}

	if (typeof find === "object") {
		if (Object.keys(find).length > 0) {
			const isArrayTransformable = Object.keys(find).every((index) =>
				index.match(/^(0|[1-9][0-9]*)$/)
			);

			return isArrayTransformable ? Object.values(find) : find;
		}
	} else {
		return find;
	}
};

export const data_set = (target, path, value, force = true) => {
	let segments = isArray(path) ? path : path.split(".");
	let [segment] = segments;

	if (segments.length === 0) {
		target = value;
	} else if (segments.length === 1 && !segments.includes("*")) {
		target[segment] = force ? value : target[segment] || value;
	} else if (segment !== "*") {
		if (!target[segment]) {
			target[segment] = {};
			target = data_set(target[segment], segments.slice(1), value, force);
		}

		let inner = data_set(target[segment], segments.slice(1), value, force);

		if (isArray(target[segment])) {
			if (force && target[segment].length) {
				target[segment] = [...target[segment]];
			} else {
				target[segment] = [...inner];
			}
		} else {
			target[segment] = force
				? { ...target[segment], ...inner }
				: { ...inner, ...target[segment] };
		}
	} else if (segment === "*") {
		const partial = segments.slice(path.indexOf("*") + 1, path.length);

		if (typeof target === "object") {
			target = Object.keys(target).reduce(
				(build, property) => ({
					...build,
					[property]: data_set(target[property], partial, value, force),
				}),
				{}
			);
		} else {
			target = data_set(target, partial, value, force);
		}
	}

	const arrayable = [
		typeof target === "object",
		Object.keys(target).length,
		Object.keys(target).every((index) => index.match(/^(0|[1-9][0-9]*)$/)),
	];

	if (arrayable.every((requirement) => requirement === true)) {
		return Object.values(target);
	}

	return target;
};

export const blank = (value) => {
	if (typeof value === "undefined") {
		return true;
	}

	if (isArray(value)) {
		return value.length === 0;
	}

	if (typeof value === "object") {
		return Object.keys(value).length === 0;
	}

	if (typeof value === "function") {
		return false;
	}

	if (typeof value === "boolean") {
		return false;
	}

	if (value === null || value.trim() === "") {
		return true;
	}

	return true;
};

export const data_fill = (target, key, value) => {
	return data_set(target, key, value, false);
};

export const camelToSnake = (string) => {
	let result = string.replace(/([A-Z])/g, "_$1").toLowerCase();

	if (result.charAt(0) === "_") {
		result = result.slice(1);
	}

	return result;
};

export const strToSnake = (string) => {
	return string.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const isAuthenticated = () => {
	let localStorageToken = window.localStorage.getItem("token");
	let localStorageUser = window.localStorage.getItem("user");

	return (
		typeof localStorageToken !== typeof undefined &&
		localStorageToken !== null &&
		typeof localStorageUser !== typeof undefined &&
		localStorageUser !== null
	);
};

export const isFullAuthenticated = () => {
	let localStoragePinToken = window.localStorage.getItem("pinToken");

	return (
		isAuthenticated() &&
		typeof localStoragePinToken !== typeof undefined &&
		localStoragePinToken !== null
	);
};

export const secondsToTime = (seconds) => {
	if (!seconds) {
		return null;
	}

	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds - hours * 3600) / 60);

	const h = hours < 10 ? `0${hours}` : hours;
	const m = minutes < 10 ? `0${minutes}` : minutes;

	let timeString = "";

	if (h !== "00") {
		timeString += `${h}:`;
	}

	timeString += `${m}`;

	return timeString;
};

export const group_by = (list, keyGetter) => {
	const map = new Map();

	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);

		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});

	return map;
};

export const array_from_group_by = (list, keyGetter) => {
	return Array.from(group_by(list, keyGetter).values());
};

export const round_number = (number) => {
	if (!number) {
		return 0;
	}

	return Math.round(number * 100) / 100;
};

export const eq = (arg1, arg2) => {
	return function () {
		return this[arg1] === (this[arg2] || arg2);
	};
};

export const notEq = (arg1, arg2) => {
	return function () {
		return this[arg1] !== (this[arg2] || arg2);
	};
};

export const not = (...args) => {
	return function () {
		return args.every((arg) => !this[arg]);
	};
};

export const and = (...args) => {
	return function () {
		return args.every((arg) => this[arg]);
	};
};

export const or = (...args) => {
	return function () {
		return args.some((arg) => this[arg]);
	};
};

export const xor = (arg1, arg2) => {
	return function () {
		return (this[arg1] && !this[arg2]) || (this[arg2] && !this[arg1]);
	};
};

export const gt = (arg1, arg2) => {
	return function () {
		return this[arg1] > (this[arg2] || arg2);
	};
};

export const gte = (arg1, arg2) => {
	return function () {
		return this[arg1] >= (this[arg2] || arg2);
	};
};

export const lt = (arg1, arg2) => {
	return function () {
		return this[arg1] < (this[arg2] || arg2);
	};
};

export const lte = (arg1, arg2) => {
	return function () {
		return this[arg1] <= (this[arg2] || arg2);
	};
};

export const sum = (...args) => {
	return function () {
		const firstArg = this[args[0]];

		// First argument is an array
		if (args.length === 1 && isArray(firstArg)) {
			return firstArg.reduce((acc, num) => acc + num, 0);
		}

		// One of passed arguments is not a number
		if (
			args.some((arg) => !Number.isFinite(arg) && !Number.isFinite(this[arg]))
		) {
			console.assert(
				true,
				'One of passed properties to "sum" helper is not a number'
			);
			return 0;
		}

		return args.reduce((acc, arg) => acc + (this[arg] || arg), 0);
	};
};

export const alias = (arg) => {
	return function () {
		return this[arg];
	};
};

export const bool = (arg) => {
	return function () {
		return Boolean(this[arg]);
	};
};

export const empty = (arg) => {
	return function () {
		return !this[arg] || this[arg].length === 0;
	};
};

export function max(arg) {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "max" requires property of array type'
		);
		return isArr ? this[arg].sort((a, b) => a < b)[0] : 0;
	};
}

export const min = (arg) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "min" requires property of array type'
		);
		return isArr ? this[arg].sort((a, b) => a > b)[0] : 0;
	};
};

export const filter = (arg, fn) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "filter" requires property of array type'
		);
		return isArr ? this[arg].filter(fn) : [];
	};
};

export const filterBy = (arg, key, value) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "filterBy" requires property of array type'
		);
		if (!isArr) return [];
		return this[arg].filter((item) => {
			return typeof item[key] !== undefined && item[key] === value;
		});
	};
};

export const find = (arg, fn) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "find" requires property of array type'
		);
		return isArr ? this[arg].find(fn) : undefined;
	};
};

export const findBy = (arg, key, value) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "findBy" requires property of array type'
		);
		if (!isArr) return undefined;
		return this[arg].find((item) => {
			return typeof item[key] !== undefined && item[key] === value;
		});
	};
};

export const map = (arg, fn) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "map" requires property of array type'
		);
		return isArr ? this[arg].map(fn) : [];
	};
};

export const mapBy = (arg, key) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "map" requires property of array type'
		);
		return isArr ? this[arg].map((item) => item[key]) : [];
	};
};

export const count = (arg) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "count" requires property of array type'
		);
		if (!isArr) return 0;
		return this[arg].length;
	};
};

export const countBy = (arg, key, value) => {
	return function () {
		const isArr = isArray(this[arg]);
		console.assert(
			isArr,
			'computed helper "countBy" requires property of array type'
		);
		if (!isArr) return 0;
		return this[arg].filter((item) => item[key] && item[key] === value).length;
	};
};

export const classObject = (...args) => {
	return function () {
		const hasProperArgs = args.every((arg) => typeof arg === "string");
		console.assert(
			hasProperArgs,
			'computed helper "classObject" requires arguments of string type'
		);

		return args.reduce((acc, arg) => {
			let className = kebabCase(arg);
			let prop = arg;

			if (arg.indexOf(":") > 0) {
				[className, prop] = arg.split(":");
			}

			acc[className] = Boolean(this[prop]);
			return acc;
		}, {});
	};
};

export const getFlooredFixed = (v, d) => {
	return Number((Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d));
};

export const getConvertedDate = (date) => {
	if (!date) {
		return null;
	}

	let convertedDate = getCurrentInstance()
		.appContext.config.globalProperties.$moment(date)
		.format("HH:mm DD.MM.YY");

	if (convertedDate === "Invalid date") {
		return null;
	}

	return convertedDate;
};

export const encryptObject = (obj) => {
	return btoa(JSON.stringify(obj));
};

export const decryptObject = (str) => {
	return JSON.parse(atob(str));
};

export const strToHash = (str) => {
	let hash = 0;
	if (str.length === 0) return hash;
	for (let i = 0; i < str.length; i++) {
		let char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash = hash & hash;
	}
	return hash;
};

export const deviceID = () => {
	const ID = `${navigator.userAgent}-${window.screen.width}x${
		window.screen.height
	}-${new Date().getTimezoneOffset()}`;

	return strToHash(ID);
};

export const parseDateString = (dateString) => {
	const [time, date] = dateString.split(" ");
	const [hours, minutes] = time.split(":");
	const [day, month, year] = date.split(".");

	return `${year}-${month}-${day}T${hours}:${minutes}`;
};
