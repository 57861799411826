import fullAuth from "@/middlewares/fullAuth"

const Roles = () => import(/* webpackChunkName: "roles" */ "../../components/pages/roles/Roles")

export default [
    {
        path: '/roles',
        component: Roles,
        name: 'roles',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
]