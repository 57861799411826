import { useStorage } from "vue3-storage";
import { HttpMethod } from "@/core/enums";
import ApiRequest from "@/Http/ApiRequest";

export const syncCache = async (
	key,
	data,
	success = () => {},
	fail = () => {}
) => {
	if (!key) {
		return false;
	}

	const storage = useStorage();

	try {
		if (!data) {
			await storage.setStorage({ key, data });
		} else {
			const storageData = await storage.getStorage({ key });
			if (!storageData) {
				await storage.setStorage({ key, data });
			} else {
				await storage.setStorage({
					key,
					data: { ...storageData.data, ...data },
				});
			}
		}
		success();
		return true;
	} catch (error) {
		fail();
		console.log(error);
		return false;
	}
};

export const cacheApiRequest = async (
	url,
	body,
	cacheKey,
	apiRequestOptions = {}
) => {
	const {
		resolve = () => {},
		reject = () => {},
		method = HttpMethod.POST,
		headers = { "Content-Type": "application/json" },
	} = apiRequestOptions;

	try {
		const internetConnected =
			window.localStorage.getItem("internetConnected") === "1";
		if (internetConnected) {
			const cacheResolve = (data) => cacheData(cacheKey, data.data);
			const cacheReject = (error) => console.log(error);
			new ApiRequest(
				url,
				body,
				resolve,
				reject,
				method,
				headers,
				cacheResolve,
				cacheReject
			);
		} else {
			const cachedData = await getCachedData(cacheKey);
			
			if (cachedData) {
				resolve(cachedData);
			} else {
				reject();
			}
		}
	} catch (error) {
		console.log(error);
	}
};

export const cacheData = async (cacheKey, data) => {
	if (!cacheKey || !data) {
		return false;
	}
	try {
		await useStorage().setStorage({ key: cacheKey, data });
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const getCachedData = async (cacheKey) => {
	try {
		const storageData = await useStorage().getStorage({ key: cacheKey });
		if (storageData) {
			return storageData.data;
		} else {
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const clearCache = async () => {
	try {
		await useStorage().clearStorage();
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const cacheKeyExists = async (cacheKey) => {
	try {
		return useStorage().hasKey(cacheKey);
	} catch (error) {
		console.log(error);
		return false;
	}
};
