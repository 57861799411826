/* eslint-disable no-console */
import { Workbox } from "workbox-window";
import { data_get } from "@/core/helpers";

let wb;

if ("serviceWorker" in navigator) {
	if (!("PushManager" in window)) {
		//push isn't supported
		console.error("Push isn't supported");
	}

	const initPush = () => {
		if (!navigator.serviceWorker.ready) {
			console.log("Service worker not ready. Exiting...");
			return;
		}

		new Promise((resolve, reject) => {
			const permissionResult = Notification.requestPermission(function (
				result
			) {
				resolve(result);
			});

			if (permissionResult) {
				permissionResult.then(resolve, reject);
			}
		}).then((permissionResult) => {
			if (permissionResult !== "granted") {
				throw new Error("We weren't granted permission.");
			}
			subscribeUser();
		});
	};

	const subscribeUser = () => {
		navigator.serviceWorker.ready
			.then((registration) => {
				const subscribeOptions = {
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(
						"BKvGL-ia3voLjvEhuNHZDW7Bny3N5P6buKurc1h7W3NWjlzzbMx3CC7FdjQoHaK31qM-MxFc8BIeFNQGopv0jPA"
					),
				};
				return registration.pushManager.subscribe(subscribeOptions);
			})
			.then((pushSubscription) => {
				let data = JSON.parse(JSON.stringify(pushSubscription));
				let user = window.localStorage.getItem("user");

				if (!user) {
					console.log("User not found. Exiting...");
					return;
				}

				user = JSON.parse(user);
				data.userId = data_get(user, "id");

				// console.log("Received PushSubscription: ", JSON.stringify(data));

				storePushSubscription(data);
			});
	};

	const urlBase64ToUint8Array = (base64String) => {
		let padding = "=".repeat((4 - (base64String.length % 4)) % 4);
		let base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

		let rawData = window.atob(base64);
		let outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	};

	const storePushSubscription = (subscription) => {
		let baseUrl = window.localStorage.getItem("apiUrl");
		let subscriptionUrl = baseUrl + "push/register";

		fetch(subscriptionUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(subscription),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Bad status code from server.");
				}
				return response.json();
			})
			.then((response) => {
				if (response.error) {
					throw new Error(response.error);
				}
				console.log("Subscription stored", response);
			})
			.catch((err) => {
				console.log("Failed to subscribe the user: ", err);
			});
	};

	wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

	wb.addEventListener("controlling", () => {
		window.location.reload();
	});

	wb.register();

	initPush();
} else {
	wb = null;
}

export default wb;
