import {
	DiscountValueType,
	OrderDeliveryType,
	PaymentCalculatorType,
	ReservationStatus,
} from "@/core/enums";

export default {
	currentInstance: null,

	apiUrl: null,
	token: null,
	pinToken: null,
	user: null,
	company: null,
	companyAssets: [],
	organizationRequisites: {
		identificationNumber: null,
		organizationName: null,
	},
	internetConnected: navigator.onLine,
	locale: null,
	translations: {},
	permissionTranslations: {},
	halls: [],
	tables: [],
	hallTables: [],
	banks: [],
	terminals: [],
	equipment: [],

	categoriesAndSaleables: [],
	carPartsCategoriesAndSaleables: [],

	searchResultProducts: [],

	//Rs

	invoices: {
		seller: [],
		buyer: [],
	},
	newInvoice: {
		invoiceMonth: null,
		invoiceYear: null,
		declarationNumber: null,
		buyer: {
			name: null,
			identificationNumber: null,
		},
		seller: {
			name: null,
			identificationNumber: null,
		},
		products: [],
	},

	// Order
	orderHall: null,
	orderTable: null,
	order: null,
	orderSaleables: [],
	orders: [],
	paginatedOrders: [],
	orderPaymentCashAmount: null,
	orderPaymentCardAmount: null,
	orderPaymentChangeAmount: null,
	orderPaymentTipAmount: null,
	orderPaymentCalculatorType: PaymentCalculatorType.CASH,
	orderClient: null,
	orderPromotion: null,
	orderPersonsCount: null,
	orderDiscount: {
		type: null, // 'CLIENT' or 'PROMOTION'
		typeId: null, // id of client or promotion
		value: 0,
		valueType: DiscountValueType.PERCENT, // 'PERCENT' or 'AMOUNT'
	},
	sendOrderToStationsDialog: false,
	orderDeliveryType: OrderDeliveryType.HALL,

	// For Supply
	supplies: {
		last_page: null,
		data: [],
	},
	employees: [],
	categories: [],
	catalogCategories: [],
	mediaFileIdsInCategoryCreate: [],
	suppliers: [],
	storages: [],
	lastMonthSupplyProducts: [],
	allProducts: [],
	allProductsForProductsTable: [],
	allCatalogProductsForProductsTable: [],
	products: [],
	productDisabledMode: false,
	productPriceAlert: false,
	realetedProductsInProductCreate: [],
	productsVerified: false,
	productsFetched: false,
	units: [],
	supplyTab: "tab-1",
	inventoriesData: [],
	supplyData: {
		storage_id: null,
		supplier_id: null,
		employee_id: null,
		cashier_id: null,
		comment: null,
		type: "order",
		status: null,
		is_evaluated: false,
		receive_employee_id: null,
		order_date: null,
		products_count: null,
		creator_id: null,
		delivered_by: [],
		total_amount: null,
		salary_money: null,
		supply_products: [],
	},
	supplySteps: {
		first: true,
		second: false,
		third: false,
		fourth: false,
		fifth: false,
		sixth: false,
	},
	supplyDisabledSteps: {
		first: false,
		second: false,
		third: false,
		fourth: false,
		fifth: false,
		sixth: false,
	},
	selectedProductIdInSupplyCreate: [],
	selectedSupply: null,
	supplyEvaluated: false,
	supplyFirstStepProducts: [],
	supplyStep3Key: 0,
	supplyStep2Key: 0,
	branches: [],
	cashflows: [],
	legalTypes: [],
	allCategories: [],
	categoriesVerified: false,
	categorySelectsTree: [],
	categoryTree: [],
	ingredientCategories: [],
	categoryForm: false,
	ingredientCategoryOpen: false,
	productList: [],
	productCachedList: [],
	productListLastPage: 1,
	productTreeList: [],
	productTreeListLastPage: null,
	ingredients: [],
	ingredientProducts: [],
	editCategory: {},
	zoomedCategory: null,
	categoryCreateEvent: false,
	productCreateData: {
		product_type: null,
		bottle_type: null,
		bottle_weight: null,
		categories: [],
		ka: {
			name: null,
			comment: null,
		},
		en: {
			name: null,
			comment: null,
		},
		ru: {
			name: null,
			comment: null,
		},
		price: null,
		price_discount: null,
		sale_quantity: null,
		barcode: null,
		modifications: [],
		properties: {
			links: [],
			link_descriptions: [],
			peeling: null,
			boiling: null,
			frying: null,
			stewing: null,
			baking: null,
		},

		meta_data: {
			insider_comment: null,
			unit_size_depth: null,
			unit_size_width: null,
			unit_size_height: null,
			unit_size_volume: null,
			unit_size_length: null,
			unit_size_thickness: null,
			unit_size_thickness_unit: null,
			unit_size_weight_net: null,
			unit_size_weight_gross: null,
		},
		unit_id: null,
		is_catalog_product: false,
		is_promo: false,
		ordering_number: null,
		unit_number_value: null,
		is_active: true,
		ingredients: [],
		station_id: "with-out",
		from_catalog: null,
		images: [],
		unitMode: false,
		imageCrop: true,
		supplierIds: [],
		rs_ge: false,
	},
	productCreateTab: "one",
	productListView: 0,
	supplyProductsData: {
		supplySearchedProducts: [],
		supplySelectedProducts: [],
		supplyNewProducts: [],
		supplyBoughtProducts: [],
		supplyEvaluatedProducts: [],
		newSelectedProducts: [],
	},
	editProduct: null,
	productCanvasCondition: null,
	productMode: false,
	productFilters: {
		product_type: null,
		bottle_type: null,
		category_id: null,
		ingredient_category_id: null,
		type: null,
		min_price: null,
		max_price: null,
		id: null,
		query: null,
		barcode: null,
		catalog_products: false,
		ingredient_products: false,
		is_active: null,
		is_modification: false,
		sorting: "SORT_BY_ALPHABET_ASC",
		station_id: null,
		with_out_stations: false,
		image: null,
		rs_ge: false,
	},
	supplyFilters: {
		searchString: null,
		supply_type: [],
	},
	siteRequisites: [],
	companyRequisites: {
		legal_type_id: null,
		name: null,
		id_number: null,
		actual_address: null,
		legal_city: null,
		legal_zip_code: null,
		legal_address: null,
		actual_city: null,
		actual_zip_code: null,
		director_first_name: null,
		director_last_name: null,
		director_father_name: null,
		director_id_number: null,
		director_phone_number: null,
		director_email: null,
		accountant_first_name: null,
		accountant_last_name: null,
		accountant_father_name: null,
		accountant_id_number: null,
		accountant_phone_number: null,
		accountant_email: null,
		number: null,
		not_same_as_legal_address: false,
		manager_id_number: null,
		manager_first_name: null,
		manager_last_name: null,
		manager_father_name: null,
		manager_phone_number: null,
		manager_email: null,
	},
	productQueryParams: null,
	productQuery: false,
	productSorting: null,
	productsLength: 0,
	productFiltersInSupplyCreate: {
		query: null,
		category_id: null,
		storage_id: null,
		supplier_id: null,
	},
	productMultiLanguage: false,

	//Stations

	stations: [],
	station: null,
	selectedStation: null,
	selectedStationTabId: null,

	withoutStationProductsCount: 0,

	userSettings: {
		width: 400,
		per_page: 50,
		session: 600,
	},

	settings: [],
	settingsLoaded: false,

	//Menu
	menuCompany: null,
	menuCompanyCategoriesAndProducts: [],
	menuCompanyRequisites: [],
	menuCatalogCategories: [],
	menuCatalogCategoryProducts: [],
	menuSelectedCatalogCategory: null,
	menuSelectedCatalogProduct: null,

	// Promotions
	promotions: [],

	// Contacts
	contacts: [],

	contactsData: {
		name: null,
		number: null,
		email: null,
		message: null,
		web_site: null,
	},

	// Clients
	clients: [],
	clientsTableList: [],

	// Dialogs
	customPersonsQuantityDialog: false,

	//Counters
	counters: [],
	listCounters: {},

	//Loaders
	loaders: {
		categories: true,
	},

	availableTimezones: [],
	availableCurrencies: [],
	availableLocales: [],

	orderPrintCheck: false,

	selectedCategoryPath: null,

	addClientPopup: false,
	clientPopupSender: null,
	orderClientsPopup: false,
	orderFilterClientPopup: false,
	reservationClientPopup: false,

	productModificationsStepper: "tab0",

	productAnimation: {
		productId: null,
		animation: false,
		animationType: "add",
	},

	// Reservations
	reservations: [],
	reservationsPagination: {
		limit: 50,
		offset: 0,
	},
	reservationEditMode: false,
	reservationData: {
		status: ReservationStatus.PENDING,
		reservationDate: null,
		reservationTime: null,
		personsCount: null,
		name: null,
		email: null,
		hall: null,
		table: null,
		phoneNumber: null,
		comment: null,
		clientId: null,
		date: null,
		cameFrom: null,
		social: null,
	},
	reservationsLoading: false,
	reservationFilterClientsPopup: false,
	reservationFilters: {
		status: null,
		client: null,
		hall: null,
		table: null,
		personsCount: null,
		from: null,
		to: null,
	},
	reservationsCount: {},
	timePicker: {
		hh: null,
		mm: null,
	},
	createOrderPopup: false,
	createOrderPopupReservationId: null,
	ordersLoaded: false,
	orderFilters: {
		halls: [],
		tables: [],
		employee: [],
		client: null,
		status: [],
		price_from: null,
		price_to: null,
		from: null,
		to: null,
	},
	changeTablePopup: false,
	basePermissions: [],
	baseRoles: [],
	employeeData: {
		firstName: null,
		lastName: null,
		email: null,
		phone: null,
		password: null,
		passwordConfirmation: null,
		roles: [],
		permissions: [],
		id: null,
		access: null,
		pin: null,
		birthDate: null,
		gender: null,
		salary: null,
		salaryType: null,
		comment: null,
		userDetails: {},
	},
	promotionData: {
		name: null,
		discount: null,
		from: null,
		to: null,
		clients: [],
		employees: [],
		id: null,
	},
	productCanvasLoader: false,
	highlightedOrderId: null,
	categoryData: {
		en: {
			name: null,
			short_name: null,
			description: null,
			comment: null,
		},
		ka: {
			name: null,
			short_name: null,
			description: null,
			comment: null,
		},
		ru: {
			name: null,
			short_name: null,
			description: null,
			comment: null,
		},
		icon: null,
		parent_id: null,
		ordering: null,
		is_catalog_category: false,
		ingredient_category: false,
		properties: {
			link_site: null,
			link_facebook: null,
			link_instagram: null,
			link_other: null,
		},
		images: [],
		links: {
			site: null,
			facebook: null,
			instagram: null,
			other: null,
		},
	},
	billingInvoices: [],
	settingsTab: null,
	inventoryMode: false,
	billingData: {
		months: null,
		paymentType: null,
		rememberCard: false,
	},
	orderPaymentPersonsCountTitle: null,
	reservationPersonsCountDialog: false,
	subscriptionData: {
		subscriptionAddons: [],
		subscriptionPrice: null,
	},
	changePricingPlanPopup: false,
	companySubscriptionInvoices: [],
	userAddWorkingScheduleData: {
		employeeId: null,
		comment: null,
		dates: [],
		hours: null,
	},
	userWorkingSchedule: [],
	usersWorkScheduleFilters: {
		keyword: null,
		employees: [],
		from: null,
		to: null,
	},
	workAttendance: {
		startAt: null,
		endAt: null,
	},
	workAttendanceStats: [],
	carPartsCategoryData: {
		en: {
			name: null,
			// short_name: null,
			description: null,
			comment: null,
		},
		ka: {
			name: null,
			// short_name: null,
			description: null,
			comment: null,
		},
		ru: {
			name: null,
			short_name: null,
			description: null,
			comment: null,
		},
		icon: null,
		parent_id: null,
		ordering: null,
		is_catalog_category: false,
		modificationId: null,
		modelId: null,
		markId: null,
		properties: {
			link_site: null,
			link_facebook: null,
			link_instagram: null,
			link_other: null,
		},
		images: [],
		links: {
			site: null,
			facebook: null,
			instagram: null,
			other: null,
		},
	},
	carPartsCategories: [],
	carPartCreateData: {
		product_type: null,
		quantity: null,
		is_active: false,
		// mark: null,
		// model: null,
		// modification: null,
		// generation: null,
		supportedCars: [
			{
				mark: null,
				model: null,
				modification: null,
				generation: null,
				selectOptions: {
					models: [],
					modifications: [],
					generations: [],
				},
			},
		],
		price: null,
		price_discount: null,
		priceCost: null,
		code: null,
		condition: null,
		is_original: false,
		categories: [],
		properties: {
			link_site: null,
			link_facebook: null,
			link_instagram: null,
			link_other: null,
		},
		images: [],
		meta: {
			car_part_condition_type: null,
			car_part_position: null,
			car_part_type: null,
			country: null,
			manufacturer_code: null,
		},
		ka: {
			name: null,
			comment: null,
		},
		en: {
			name: null,
			comment: null,
		},
		ru: {
			name: null,
			comment: null,
		},
	},
	carMarks: [],
	carModels: [],
	carModifications: [],
	carGenerations: [],
	carParts: [],
	supplierData: {
		name: null,
		email: null,
		identity: null,
		legalType: null,
		phone: null,
		address: null,
		comment: null,
	},
	supplierEditMode: false,
	supplierIdToDelete: null,
	showDeleteSupplierDialog: false,
	promotionClientsPopup: false,
	saleables: [],
	startWorkingDialog: false,
	finishWorkingDialog: false,
	currentAttendanceRule: null,
	upcomingAttendanceHoursForToday: null,
	attendanceForTomorrow: null,
	attendanceForYesterday: null,
	attendanceForToday: null,
	paginatedProducts: [],
	paginatedCarParts: [],
	incomeRequestsView: false,
	posRequisitesData: {
		nameRequisites: {
			ka: {
				COMPANY_NAME: null,
				MANAGER_NAME: null,
			},
			ru: {
				COMPANY_NAME: null,
				MANAGER_NAME: null,
			},
			en: {
				COMPANY_NAME: null,
				MANAGER_NAME: null,
			},
		},

		phoneRequisites: {
			PHONE_1: null,
			PHONE_2: null,
			PHONE_3: null,
			PHONE_4: null,
			PHONE_WHATSAPP: null,
			PHONE_VIBER: null,
			PHONE_TELEGRAM: null,
			PHONE_TELEGRAM_NAME: null,
		},
	},
};
