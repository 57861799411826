import fullAuth from "@/middlewares/fullAuth"

const Contacts = () => import(/* webpackChunkName: "clients" */ "../../components/pages/Contacts/Contacts")
const UpdateContact = () => import(/* webpackChunkName: "clients" */ "../../components/pages/Contacts/UpdateContact")

export default [
    {
        path: '/contacts',
        component: Contacts,
        name: 'contacts',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/contacts/:id',
        component: UpdateContact,
        name: 'edit-contact',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
]