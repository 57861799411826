import _ from "lodash";
import { ReservationStatus } from "@/core/enums";
import { userDetailsDefaultValue } from "@/core/defaultValues";

export default {
	setCurrentInstance(state, instance) {
		state.currentInstance = instance;
	},

	setApiUrl(state, apiUrl) {
		state.apiUrl = apiUrl;
	},

	setToken(state, token) {
		state.token = token;
	},

	setPinToken(state, pinToken) {
		state.pinToken = pinToken;
	},

	setUser(state, user) {
		state.user = user;
	},

	setCompany(state, company) {
		state.company = company;
	},

	setRsInvoiceSeller(state, invoices) {
		state.invoices.seller = invoices;
	},

	setRsInvoiceBuyer(state, invoices) {
		state.invoices.buyer = invoices;
	},

	setNewProductInNewInvoice(state, product) {
		state.newInvoice.products.push(product);
	},

	removeProductInNewInvoice(state, key) {
		state.newInvoice.products.splice(key);
	},

	setCompanyAssets(state, companyAssets) {
		state.companyAssets = companyAssets;
	},

	setOrganizationRequisites(state, organizationRequisites) {
		state.organizationRequisites = organizationRequisites;
	},

	setInternetConnected(state, internetConnected) {
		state.internetConnected = internetConnected;
	},

	setLocale(state, locale) {
		state.locale = locale;
	},

	setTranslations(state, translations) {
		state.translations = translations;
	},

	setPermissionTranslations(state, permissionTranslations) {
		state.permissionTranslations = permissionTranslations;
	},

	setHalls(state, halls) {
		state.halls = halls;
	},

	pushHall(state, hall) {
		state.halls.push(hall);
	},

	removeHall(state, hall) {
		state.halls.splice(
			state.halls.findIndex((h) => h.id === hall.id),
			1
		);
	},

	setTables(state, tables) {
		state.tables = tables;
	},

	pushTable(state, table) {
		state.tables.push(table);
	},

	updateTable(state, table) {
		const index = state.tables.findIndex((t) => t.id === table.id);
		state.tables[index] = table;
	},

	setBanks(state, banks) {
		state.banks = banks;
	},

	setTerminals(state, terminals) {
		state.terminals = terminals;
	},

	setEquipment(state, equipment) {
		state.equipment = equipment;
	},

	pushTerminal(state, terminal) {
		state.terminals.push(terminal);
	},

	removeTerminal(state, terminal) {
		state.terminals.splice(
			state.terminals.findIndex((t) => t.id === terminal.id),
			1
		);
	},

	removeTable(state, table) {
		state.tables.splice(
			state.tables.findIndex((t) => t.id === table.id),
			1
		);
	},

	setOrderHall(state, orderHall) {
		state.orderHall = orderHall;
	},

	setOrderTable(state, orderTable) {
		state.orderTable = orderTable;
	},

	setOrder(state, order) {
		state.order = order;
	},

	setOrders(state, orders) {
		state.orders = orders;
	},

	setPaginatedOrders(state, paginatedOrders) {
		state.paginatedOrders = paginatedOrders;
	},

	replaceOrderInOrdersList(state, order) {
		const index = state.orders.findIndex((o) => o.id === order.id);
		if (index !== -1) {
			state.orders.splice(index, 1, order);
		}
	},

	replaceOrderInPaginatedOrdersList(state, order) {
		const index = state.paginatedOrders.findIndex((o) => o.id === order.id);

		if (index !== -1) {
			state.paginatedOrders.splice(index, 1, order);
		}
	},

	addOrderInOrdersList(state, order) {
		state.orders.unshift(order);
	},

	setOrderSaleables(state, orderSaleables) {
		state.orderSaleables = orderSaleables;
	},

	setSaleablesToOrder(state, saleablesData) {
		const { orderId, saleables } = saleablesData;
		let order = state.orders.find((o) => o.id === orderId);
		order.products = saleables;

		state.orders = state.orders.map((o) => (o.id === orderId ? order : o));
	},

	pushOrderSaleable(state, orderSaleable) {
		state.orderSaleables.push(orderSaleable);
	},

	unshiftOrderSaleable(state, orderSaleable) {
		state.orderSaleables.unshift(orderSaleable);
	},

	setOrderPaymentCashAmount(state, orderPaymentCashAmount) {
		state.orderPaymentCashAmount = orderPaymentCashAmount;
	},

	setOrderPaymentCardAmount(state, orderPaymentCardAmount) {
		state.orderPaymentCardAmount = orderPaymentCardAmount;
	},

	setOrderPaymentChangeAmount(state, orderPaymentChangeAmount) {
		state.orderPaymentChangeAmount = orderPaymentChangeAmount;
	},

	setOrderPaymentTipAmount(state, orderPaymentTipAmount) {
		state.orderPaymentTipAmount = orderPaymentTipAmount;
	},

	setOrderPaymentCalculatorType(state, orderPaymentCalculatorType) {
		state.orderPaymentCalculatorType = orderPaymentCalculatorType;
	},

	setOrderClient(state, orderClient) {
		state.orderClient = orderClient;
	},

	setClientInOrder(state, client) {
		let order = state.order;
		order.client = client;

		state.order = order;

		// Update order in orders list
		state.orders = state.orders.map((o) => (o.id === order.id ? order : o));
	},

	setOrderPromotion(state, orderPromotion) {
		state.orderPromotion = orderPromotion;
	},

	setOrderPersonsCount(state, orderPersonsCount) {
		state.orderPersonsCount = orderPersonsCount;
	},

	setOrderDiscount(state, orderDiscount) {
		state.orderDiscount = orderDiscount;
	},

	setSendOrderToStationsDialog(state, sendOrderToStationsDialog) {
		state.sendOrderToStationsDialog = sendOrderToStationsDialog;
	},

	setOrderDeliveryType(state, orderDeliveryType) {
		state.orderDeliveryType = orderDeliveryType;
	},

	setCategoriesAndSaleables(state, categoriesAndSaleables) {
		state.categoriesAndSaleables = categoriesAndSaleables;
	},

	setCarPartsCategoriesAndSaleables(state, categoriesAndSaleables) {
		state.carPartsCategoriesAndSaleables = categoriesAndSaleables;
	},

	setSearchResultProducts(state, searchResultProducts) {
		state.searchResultProducts = searchResultProducts;
	},

	setSupplieData(state, supplies) {
		state.supplies.data = supplies;
	},

	setSupplieLastPage(state, supplies) {
		state.supplies.last_page = supplies;
	},

	unshiftSupply(state, supply) {
		state.supplies.unshift(supply);
	},

	setCategories(state, categories) {
		state.categories = categories;
	},

	pushMediaFileIdInCategoryCreate(state, id) {
		state.mediaFileIdsInCategoryCreate.push(id);
	},

	resetMediaFileIdsInCategoryCreate(state) {
		state.mediaFileIdsInCategoryCreate = [];
	},

	setSuppliers(state, suppliers) {
		state.suppliers = suppliers;
	},

	setStorages(state, storages) {
		state.storages = storages;
	},

	setLastMonthSupplyProducts(state, lastMonthSupplyProducts) {
		state.lastMonthSupplyProducts = lastMonthSupplyProducts;
	},

	removeLastMonthSupplyProducts(state, lastMonthSupplyProduct) {
		_.remove(
			state.lastMonthSupplyProducts,
			(product) => product.id === lastMonthSupplyProduct.id
		);
	},

	setRealetedProductsInProductCreate(state, data) {
		state.realetedProductsInProductCreate = data;
	},

	resetProductCreateData(state) {
		state.productCreateData = {
			product_type: null,
			bottle_type: null,
			bottle_weight: null,
			categories: [],
			ka: {
				name: null,
				comment: null,
			},
			en: {
				name: null,
				comment: null,
			},
			ru: {
				name: null,
				comment: null,
			},
			price: null,
			price_discount: null,
			sale_quantity: null,
			barcode: null,
			modifications: [],
			properties: {
				links: [],
				link_descriptions: [],
				peeling: null,
				boiling: null,
				frying: null,
				stewing: null,
				baking: null,
			},
			meta_data: {
				insider_comment: null,
				unit_size_depth: null,
				unit_size_width: null,
				unit_size_height: null,
				unit_size_volume: null,
				unit_size_length: null,
				unit_size_thickness: null,
				unit_size_thickness_unit: null,
				unit_size_weight_net: null,
				unit_size_weight_gross: null,
				cooking_date: null,
			},
			unit_id: null,
			is_catalog_product: false,
			is_promo: false,
			ordering_number: null,
			unit_number_value: null,
			is_active: true,
			ingredients: [],
			station_id: "with-out",
			shelf_life_until: null,
			from_catalog: null,
			images: [],
			unitMode: false,
			imageCrop: true,
			supplierIds: [],
			rs_ge: false,
		};
	},

	pushProducts(state, product) {
		state.products.push(product);
	},

	unshiftProducts(state, product) {
		state.productList.unshift(product);
	},

	removeFromProductsById(state, productId) {
		let products = state.productList;
		products = products.filter((product) => product.id !== productId);
		state.productList = products;
	},

	changeProductsVerified(state, value = true) {
		state.productsVerified = value;
	},

	changeProductsFetched(state, value = true) {
		state.productsFetched = value;
	},

	categoriesVerified(state) {
		state.categoriesVerified = true;
	},

	setUnits(state, units) {
		state.units = units;
	},

	setSupplyTab(state, tab) {
		state.supplyTab = tab;
	},

	setSupplyData(state, data) {
		state.supplyData = data;
	},

	setSupplyThirdStepProducts(state, data) {
		state.supplyData.thirdStepProducts = data;
	},

	setSupplyCashflow(state, data) {
		state.supplyData.cashflow = data;
	},

	removeProductFromThirdStepProducts(state, productId) {
		_.remove(
			state.supplyData.thirdStepProducts,
			(xProduct) => xProduct.id === productId
		);
	},

	setSelectedSupply(state, id) {
		state.selectedSupply = id;
	},

	setSupplyEvaluated(state, condition) {
		state.supplyEvaluated = condition;
	},

	setBranches(state, data) {
		state.branches = data;
	},

	setCashflows(state, data) {
		state.cashflows = data;
	},

	setLegalTypes(state, data) {
		state.legalTypes = data;
	},

	pushCashflow(state, cashflow) {
		state.cashflows.unshift(cashflow);
	},

	setAllCategories(state, data) {
		state.allCategories = data;
	},

	setImageInAllCategory(state, data) {
		let key = state.allCategories.findIndex((h) => h.id === data.id);

		state.allCategories[key].icon_url = data.icon_url;
		state.allCategories[key].path = data.icon_url;
	},

	setCategorySelectsTree(state, data) {
		let tree = [];
		let generatedTree = [];
		_.forEach(data, function (category) {
			if (!category.parent_id) {
				category.text = category.name;
				category.children = [];
				tree.push(category);
			}
		});

		_.forEach(data, function (category) {
			if (category.parent_id) {
				category.text = category.name;
				let parent = tree.findIndex((h) => h.id === category.parent_id);
				if (parent >= 0) {
					tree[parent].children.push(category);
				}
			}
		});

		_.forEach(tree, function (category) {
			generatedTree.push({
				value: category.id,
				title: category.name + " (" + category.products_count + ")",
				detail: category.name + " (" + category.products_count + ")",
				products_count: category.products_count,
			});

			_.forEach(category.children, function (category) {
				generatedTree.push({
					value: category.id,
					title: " - " + category.name + " (" + category.products_count + ")",
					detail: category.name + "(" + category.products_count + ")",
					products_count: category.products_count,
				});
			});
		});

		state.categorySelectsTree = generatedTree;
	},

	setIngredientCategories(state, data) {
		state.ingredientCategories = data;
	},

	removeIngredientCategories(state, key) {
		state.ingredientCategories.splice(key, 1);
	},

	setProductList(state, data) {
		state.productList = data;
	},

	setProductCachedList(state, data) {
		state.productCachedList = data;
	},

	setProductListLastPage(state, data) {
		state.productListLastPage = data;
	},

	setProductTreeList(state, data) {
		state.productTreeList = data;
	},

	setProductTreeListLastPage(state, data) {
		state.productTreeListLastPage = data;
	},

	setCategoryTree(state, data) {
		state.categoryTree = data;
	},

	setIngredientProducts(state, data) {
		state.ingredientProducts = data;
	},

	pushIngredientProduct(state, product) {
		state.ingredientProducts.push(product);
	},

	setProductCreateTab(state, tab) {
		state.productCreateTab = tab;
	},

	setProductCreateData(state, data) {
		state.productCreateData = data;
	},

	addProductCreateDataModification(state, modification) {
		state.productCreateData.modifications.push(modification);
	},

	removeProductCreateDataModification(state, key) {
		state.productCreateData.modifications.splice(key, 1);
	},

	setMenuCompany(state, data) {
		state.menuCompany = data;
	},

	setMenuCompanyCategoriesAndProducts(state, data) {
		state.menuCompanyCategoriesAndProducts = data;
	},

	setMenuCompanyRequisites(state, data) {
		state.menuCompanyRequisites = data;
	},

	addProductCreateDataLink(state, data) {
		state.productCreateData.properties.links.push(data);
		state.productCreateData.properties.link_descriptions.push("");
	},

	setInventoriesData(state, data) {
		state.inventoriesData = data;
	},

	setNewInventoryInInventoryData(state, inventory) {
		state.inventoriesData.unshift(inventory);
	},

	updateInventoryInInventoryData(state, inventory) {
		let inventoryKey = state.inventoriesData.findIndex(
			(h) => h.id === inventory.id
		);

		state.inventoriesData[inventoryKey] = inventory;
	},

	addProductCreateDataIngredient(state, ingredient) {
		state.productCreateData.ingredients.push(ingredient);
	},

	purgeProductDataIngredient(state, key) {
		state.productCreateData.ingredients.splice(key, 1);
	},

	setEditCategory(state, category) {
		state.editCategory = category;
	},

	setZoomedCategory(state, category) {
		state.zoomedCategory = category;
	},

	setCategoryIdInProductsFilter(state, categoryId) {
		state.productFilters.category_id = categoryId ? parseInt(categoryId) : null;
	},

	setCatalogCategories(state, data) {
		state.catalogCategories = data;
	},

	setCategoryCreateEvent(state, condition) {
		state.categoryCreateEvent = condition;
	},

	purgeProduct(state, key) {
		state.productList.splice(key, 1);
	},

	setProductListView(state, view) {
		state.productListView = view;
	},

	setMenuCatalogCategories(state, catalogCategories) {
		state.menuCatalogCategories = catalogCategories;
	},

	setMenuCatalogCategoryProducts(state, catalogCategoryProducts) {
		state.menuCatalogCategoryProducts = catalogCategoryProducts;
	},

	setMenuSelectedCatalogCategory(state, catalogCategory) {
		state.menuSelectedCatalogCategory = catalogCategory;
	},

	setMenuSelectedCatalogProduct(state, catalogProduct) {
		state.menuSelectedCatalogProduct = catalogProduct;
	},

	setStations(state, data) {
		state.stations = data;
	},

	unshiftStation(state, station) {
		state.stations.unshift(station);
	},

	setSelectedStation(state, station) {
		state.selectedStation = station;
	},

	setSelectedStationTabId(state, stationId) {
		state.selectedStationTabId = stationId;
	},

	setWithoutStationProductsCount(state, count) {
		state.withoutStationProductsCount = count;
	},

	setStation(state, station) {
		state.station = station;
	},

	updateStation(state, data) {
		let key = state.stations.findIndex((h) => h.id === data.id);

		state.stations[key] = data.data;
	},

	deleteStation(state, id) {
		state.stations.splice(
			state.stations.findIndex((h) => h.id === id),
			1
		);
	},

	pushNewProductsInSupplyProductsData(state, product) {
		state.supplyProductsData.supplyNewProducts.push(product);
	},

	deleteNewProductsInSupplyProductsData(state, key) {
		state.supplyProductsData.supplyNewProducts.splice(key, 1);
	},

	deleteSelectedProductsInSupplyProductsData(state, key) {
		state.supplyProductsData.supplySelectedProducts.splice(key, 1);
	},

	setSelectedProductInSupplyProductsData(state, product) {
		let checkIfProductExists =
			state.supplyProductsData.supplySelectedProducts.findIndex(
				(h) => h.id === product.id
			);

		if (checkIfProductExists >= 0) {
			state.supplyProductsData.supplySelectedProducts[checkIfProductExists] =
				product;

			return;
		}

		state.supplyProductsData.supplySelectedProducts.push(product);
	},

	setSupplyBoughtProductsData(state, product) {
		state.supplyProductsData.supplyBoughtProducts.push(product);
	},

	setSupplyEvaluatedProducts(state, product) {
		state.supplyProductsData.supplyEvaluatedProducts.push(product);
	},

	setNewSelectedProducts(state, product) {
		state.supplyProductsData.newSelectedProducts.push(product);
	},

	resetSupplyProductsData(state) {
		state.supplyProductsData = {
			supplySearchedProducts: [],
			supplySelectedProducts: [],
			supplyNewProducts: [],
			supplyBoughtProducts: [],
			supplyEvaluatedProducts: [],
			newSelectedProducts: [],
		};
	},

	resetSupplyProductsDataBoughtProducts(state) {
		state.supplyProductsData.supplyBoughtProducts = [];
	},

	resetSupplyProductsDataEvaluatedProducts(state) {
		state.supplyProductsData.supplyEvaluatedProducts = [];
	},

	setProductCanvasCondition(state, condition) {
		state.productCanvasCondition = condition;

		// Reset state
		if (!condition) {
			state.productDisabledMode = false;
		}
	},

	setProductMode(state, condition) {
		state.productMode = condition;
	},

	setEditProduct(state, productId) {
		state.editProduct = productId;
	},

	setProductDataUnitMode(state, condition) {
		state.productCreateData.unitMode = condition;
	},

	setProductFilters(state, data) {
		if (data.category_id === "without") {
			state.productFilters = data;
			return;
		}

		data.category_id = data.category_id ? parseInt(data.category_id) : null;

		state.productFilters = data;
	},

	setProductQueryParams(state, queryParams) {
		state.productQueryParams = queryParams;
	},

	setProductDisabledMode(state, condition) {
		state.productDisabledMode = condition;
	},

	setProductPriceAlert(state, condition) {
		state.productPriceAlert = condition;
	},

	setProductSorting(state, sort) {
		state.productSorting = sort;
	},

	setSiteRequisites(state, data) {
		state.siteRequisites = data;
	},

	setCompanyRequisites(state, data) {
		state.companyRequisites = data;
	},

	updateStateByKey(state, data) {
		state[data.key] = data.value;
	},

	// Promotions
	setPromotions(state, data) {
		state.promotions = data;
	},

	// Clients
	setClients(state, data) {
		state.clients = data;
	},

	setClientsTableList(state, data) {
		state.clientsTableList = data;
	},

	changeProductMultiLanguageCondition(state, condition) {
		state.productMultiLanguage = condition;
	},

	setSupplySecondStepCondition(state, condition) {
		state.supplySteps.second = condition;
	},

	setSupplyThirdStepCondition(state, condition) {
		state.supplySteps.third = condition;
	},

	setSupplyFourthStepCondition(state, condition) {
		state.supplySteps.fourth = condition;
	},

	setSupplyFifthStepCondition(state, condition) {
		state.supplySteps.fifth = condition;
	},

	setDefaultSupplySteps(state) {
		state.supplySteps = {
			first: true,
			second: false,
			third: false,
			fourth: false,
			fifth: false,
			sixth: false,
		};
	},

	setDefaultProductFiltersInSupplyCreate(state) {
		state.productFiltersInSupplyCreate = {
			query: null,
			category_id: null,
			storage_id: null,
			supplier_id: null,
		};
	},

	setSupplyDisabledFirstStepCondition(state, condition) {
		state.supplyDisabledSteps.first = condition;
	},

	setSelectedProductIdInSupplyCreate(state, saleableId) {
		state.selectedProductIdInSupplyCreate.push({
			id: saleableId,
		});
	},

	removeSelectedProductIdInSupplyCreate(state, productId) {
		state.selectedProductIdInSupplyCreate[
			state.selectedProductIdInSupplyCreate.findIndex((h) => h.id === productId)
		].id = null;
	},

	setDefaultToSupplyDisabledSteps(state) {
		state.supplyDisabledSteps = {
			first: false,
			second: false,
			third: false,
			fourth: false,
			fifth: false,
			sixth: false,
		};
	},

	setUserSettings(state, data) {
		state.userSettings = data;
	},

	setSettings(state, settings) {
		state.settings = settings;
	},

	setSettingsLoaded(state, condition) {
		state.settingsLoaded = condition;
	},

	setSupplyDisabledSecondStepCondition(state, condition) {
		state.supplyDisabledSteps.second = condition;
	},

	setSupplyDisabledThirdStepCondition(state, condition) {
		state.supplyDisabledSteps.third = condition;
	},

	setSupplyDisabledFourthStepCondition(state, condition) {
		state.supplyDisabledSteps.fourth = condition;
	},

	setSupplyDisabledFifthStepCondition(state, condition) {
		state.supplyDisabledSteps.fifth = condition;
	},

	setCustomPersonsQuantityDialog(state, condition) {
		state.customPersonsQuantityDialog = condition;
	},

	setCounters(state, data) {
		state.counters = data;
	},

	setListCounters(state, data) {
		state.listCounters = data;
	},

	//Loaders
	changeCategoryLoader(state, condition) {
		state.loaders.categories = condition;
	},
	//End Loaders

	setAvailableCurrencies(state, data) {
		state.availableCurrencies = data;
	},

	setAvailableLocales(state, data) {
		state.availableLocales = data;
	},

	setAvailableTimezones(state, data) {
		state.availableTimezones = data;
	},

	setProductsLength(state, length) {
		state.productsLength = length;
	},

	setAllProducts(state, data) {
		state.allProducts = data;
	},

	setAllProductsForProductsTable(state, data) {
		state.allProductsForProductsTable = data;
	},

	setAllCatalogProductsForProductsTable(state, data) {
		state.allCatalogProductsForProductsTable = data;
	},

	setCategoryFormCondition(state, condition) {
		state.categoryForm = condition;
	},

	setIngredientCategoryOpen(state, condition) {
		state.ingredientCategoryOpen = condition;
	},

	removeProductFromProductCachedList(state, productId) {
		let cachedList = state.productCachedList || [];
		let index = cachedList.findIndex((h) => h.id === productId);

		cachedList.splice(index, 1);

		state.productCachedList = cachedList;

		let allProductsForProductsTable = state.allProductsForProductsTable || [];
		let index2 = allProductsForProductsTable.findIndex(
			(h) => h.id === productId
		);

		allProductsForProductsTable.splice(index2, 1);
		state.allProductsForProductsTable = allProductsForProductsTable;
	},

	updateProductInProductCachedList(state, product) {
		let key = state.productCachedList.findIndex((h) => h.id === product.id);
		state.productCachedList[key] = product;

		state.productCachedList[key].action = false;
		state.productCachedList[key].editMode = false;

		let categories = state.productCachedList[key].categories;
		state.productCachedList[key].selectedCategories = [];

		_.forEach(categories, function (category) {
			state.productCachedList[key].selectedCategories.push(category.id);
		});

		let products = state.allProductsForProductsTable || [];
		let productsTableKey = products.findIndex((h) => h.id === product.id);
		products[productsTableKey] = product;
		state.allProductsForProductsTable = products;
	},

	addProductInProductCachedList(state, product) {
		state.productCachedList.unshift(product);

		state.productAnimation = {
			productId: product.id,
			animation: true,
			animationType: "add",
		};
	},

	setSupplyFilters(state, filters) {
		state.supplyFilters = filters;
	},

	setSelectedCategoryPath(state, path) {
		state.selectedCategoryPath = path;
	},

	setAddClientPopup(state, condition) {
		state.addClientPopup = condition;
	},

	setClientPopupSender(state, sender) {
		state.clientPopupSender = sender;
	},

	setOrderClientsPopup(state, condition) {
		state.orderClientsPopup = condition;
	},

	setOrderFilterClientPopup(state, condition) {
		state.orderFilterClientPopup = condition;
	},

	setReservationClientPopup(state, condition) {
		state.reservationClientPopup = condition;
	},

	setProductModificationsStepper(state, value) {
		state.productModificationsStepper = value;
	},

	setProductAnimation(state, animationData) {
		state.productAnimation = animationData;
	},

	setReservations(state, reservations) {
		state.reservations = reservations;
	},

	setReservationsPagination(state, pagination) {
		state.reservationsPagination = pagination;
	},

	replaceReservationInReservations(state, reservation) {
		let key = state.reservations.findIndex((h) => h.id === reservation.id);
		let res = state.reservations;

		res[key] = reservation;

		let newReservations = res
			.filter((reservation) => reservation.newlyReplaced === true)
			.map((reservation) => {
				reservation.status = ReservationStatus.VIEWED;
				return reservation;
			});
		let uniqById = _.uniqBy(res, "id");

		newReservations.forEach((r) => {
			uniqById = _.remove(uniqById, (item) => {
				return item.id !== r.id;
			});

			uniqById.push(r);
		});

		state.reservations = uniqById;
	},

	addReservationInReservations(state, reservation) {
		state.reservations.unshift(reservation);
	},

	setReservationEditMode(state, condition) {
		state.reservationEditMode = condition;
	},

	setReservationData(state, data) {
		state.reservationData = data;
	},

	setReservationsLoading(state, condition) {
		state.reservationsLoading = condition;
	},

	setReservationFilterClientsPopup(state, condition) {
		state.reservationFilterClientsPopup = condition;
	},

	setReservationFilters(state, filters) {
		state.reservationFilters = filters;
	},

	setReservationsCount(state, counts) {
		state.reservationsCount = counts;
	},

	setTimePicker(state, timePicker) {
		state.timePicker = timePicker;
	},

	setTimePickerHH(state, hh) {
		state.timePicker.hh = hh;
	},

	setTimePickerMM(state, mm) {
		state.timePicker.mm = mm;
	},

	setCreateOrderPopup(state, condition) {
		state.createOrderPopup = condition;
	},

	setCreateOrderPopupReservationId(state, reservationId) {
		state.createOrderPopupReservationId = reservationId;
	},

	setOrdersLoaded(state, condition) {
		state.ordersLoaded = condition;
	},

	setOrderFilters(state, filters) {
		state.orderFilters = filters;
	},

	resetOrderFilters(state) {
		state.orderFilters = {
			halls: [],
			tables: [],
			employee: [],
			client: null,
			status: [],
			price_from: null,
			price_to: null,
			from: null,
			to: null,
		};
	},

	setChangeTablePopup(state, condition) {
		state.changeTablePopup = condition;
	},

	setBasePermissions(state, permission) {
		state.basePermissions = permission;
	},

	setBaseRoles(state, roles) {
		state.baseRoles = roles;
	},

	setEmployeeData(state, data) {
		state.employeeData = data;
	},

	resetEmployeeData(state) {
		state.employeeData = {
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			password: null,
			roles: [],
			permissions: [],
			id: null,
			access: null,
			pin: null,
			birthDate: null,
			gender: null,
			salary: null,
			salaryType: null,
			comment: null,
			userDetails: userDetailsDefaultValue,
		};
	},

	setEmployees(state, employees) {
		state.employees = employees;
	},

	replaceEmployeeInEmployees(state, employee) {
		let key = state.employees.findIndex((h) => h.id === employee.id);
		let res = state.employees;

		res[key] = employee;

		state.employees = res;
	},

	setPromotionData(state, data) {
		state.promotionData = data;
	},

	setContactsData(state, data) {
		state.contactsData = data;
	},

	setContacts(state, data) {
		state.contacts = data;
	},

	setProductCanvasLoader(state, condition) {
		state.productCanvasLoader = condition;
	},

	setHighlightedOrderId(state, id) {
		state.highlightedOrderId = id;
	},

	setCategoryData(state, data) {
		state.categoryData = data;
	},

	setBillingInvoices(state, invoices) {
		state.billingInvoices = invoices;
	},

	setSettingsTab(state, tab) {
		state.settingsTab = tab;
	},

	setInventoryMode(state, condition) {
		state.inventoryMode = condition;
	},

	setBillingData(state, data) {
		state.billingData = data;
	},

	setOrderPaymentPersonsCountTitle(state, title) {
		state.orderPaymentPersonsCountTitle = title;
	},

	setReservationPersonsCountDialog(state, condition) {
		state.reservationPersonsCountDialog = condition;
	},

	setSubscriptionData(state, data) {
		state.subscriptionData = data;
	},

	setChangePricingPlanPopup(state, condition) {
		state.changePricingPlanPopup = condition;
	},

	setCompanySubscriptionInvoices(state, invoices) {
		state.companySubscriptionInvoices = invoices;
	},

	setUserAddWorkingScheduleData(state, data) {
		state.userAddWorkingScheduleData = data;
	},

	setUserWorkingSchedule(state, data) {
		state.userWorkingSchedule = data;
	},

	setUsersWorkScheduleFilters(state, data) {
		state.usersWorkScheduleFilters = data;
	},

	setWorkAttendance(state, data) {
		state.workAttendance = data;
	},

	setWorkAttendanceStats(state, data) {
		state.workAttendanceStats = data;
	},

	setCarPartsCategoryData(state, data) {
		state.carPartsCategoryData = data;
	},

	setCarPartsCategories(state, data) {
		state.carPartsCategories = data;
	},

	setCarPartCreateData(state, data) {
		state.carPartCreateData = data;
	},

	setCarMarks(state, data) {
		state.carMarks = data;
	},

	setCarModels(state, data) {
		state.carModels = data;
	},

	setCarModifications(state, data) {
		state.carModifications = data;
	},

	setCarGenerations(state, data) {
		state.carGenerations = data;
	},

	setCarParts(state, data) {
		state.carParts = data;
	},

	updateCarPartInCarParts(state, data) {
		let key = state.carParts.findIndex((h) => h.id === data.id);
		state.carParts[key] = data;
	},

	addCarPartInCarParts(state, data) {
		state.carParts.unshift(data);
	},

	setSupplierData(state, data) {
		state.supplierData = data;
	},

	setSupplierEditMode(state, condition) {
		state.supplierEditMode = condition;
	},

	setSupplierIdToDelete(state, id) {
		state.supplierIdToDelete = id;
	},

	setShowDeleteSupplierDialog(state, condition) {
		state.showDeleteSupplierDialog = condition;
	},

	setPromotionClientsPopup(state, value) {
		state.promotionClientsPopup = value;
	},

	setSaleables(state, data) {
		state.saleables = data;
	},

	setStartWorkingDialog(state, value) {
		state.startWorkingDialog = value;
	},

	setFinishWorkingDialog(state, value) {
		state.finishWorkingDialog = value;
	},

	setCurrentAttendanceRule(state, value) {
		state.currentAttendanceRule = value;
	},

	setUpcomingAttendanceHoursForToday(state, value) {
		state.upcomingAttendanceHoursForToday = value;
	},

	setAttendanceForTomorrow(state, value) {
		state.attendanceForTomorrow = value;
	},

	setAttendanceForYesterday(state, value) {
		state.attendanceForYesterday = value;
	},

	setAttendanceForToday(state, value) {
		state.attendanceForToday = value;
	},

	setPaginatedProducts(state, value) {
		state.paginatedProducts = value;
	},

	setPaginatedCarParts(state, value) {
		state.paginatedCarParts = value;
	},

	setNameRequisitesInPosRequisites(state, value) {
		state.posRequisitesData.nameRequisites = value;
	},

	setPhoneRequisitesInPosRequisites(state, value) {
		state.posRequisitesData.phoneRequisites = value;
	},
};
