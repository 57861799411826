import fullAuth from "@/middlewares/fullAuth";

const Products = () => import('../../components/pages/Products')
const Inventory = () => import('../../components/pages/inventory/Inventory.vue')
const ProductCreate = () => import('../../components/pages/Product/Create')

export default [
    {
        path: '/products',
        component: Products,
        name: 'products',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/inventory/products',
        component: Products,
        name: 'products-inventory-mode',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/inventory/view/products',
        component: Products,
        name: 'products-inventory-view-mode',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/inventory',
        component: Inventory,
        name: 'inventory',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/product/create',
        component: ProductCreate,
        name: 'product-create',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
    {
        path: '/product/:productId',
        component: ProductCreate,
        name: 'product-edit',
        meta: {
            middleware: [fullAuth],
            layout: 'PosLayout'
        }
    },
]
