import { createRouter, createWebHistory } from "vue-router";
import routeIndex from "./routes/routeIndex";
import authentication from "./routes/authentication";
import cashflow from "./routes/cashflow";
import catalog from "./routes/catalog";
import category from "./routes/category";
import contacts from "./routes/contacts";
import contracts from "./routes/contracts";
import hall from "./routes/hall";
import menu from "./routes/menu";
import notFound from "./routes/notFound";
import order from "./routes/order";
import product from "./routes/product";
import settings from "./routes/settings";
import station from "./routes/station";
import supply from "./routes/supply";
import clients from "./routes/clients";
import users from "./routes/users";
import usersWorkSchedule from "./routes/usersWorkSchedule";
import usersSalaries from "./routes/usersSalaries";
import promotions from "./routes/promotions";
import stats from "./routes/stats";
import permissions from "./routes/permissions";
import roles from "./routes/roles";
import reservation from "./routes/reservation";
import rs from "./routes/rs";
import videocontrol from "./routes/videocontrol";
import crm from "./routes/crm";
import billing from "./routes/billing";
import templates from "./routes/templates";
import instruction from "./routes/instruction";
import aboutUs from "./routes/aboutUs";

const router = createRouter({
	history: createWebHistory(),

	scrollBehavior() {
		return { top: 0 };
	},

	routes: [
		...routeIndex,
		...authentication,
		...cashflow,
		...catalog,
		...category,
		...contacts,
		...hall,
		...menu,
		...order,
		...product,
		...settings,
		...station,
		...supply,
		...clients,
		...users,
		...promotions,
		...stats,
		...permissions,
		...roles,
		...reservation,
		...rs,
		...notFound,
		...videocontrol,
		...usersWorkSchedule,
		...usersSalaries,
		...contracts,
		...crm,
		...billing,
		...templates,
		...instruction,
		...aboutUs,
	],
});

export default router;
