import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import store from "./store";
import router from "./router/router";
import toastr from "toastr";
import vuetify from "./plugins/vuetify";
import wb from "./registerServiceWorker";
import Vue3Storage, { StorageType } from "vue3-storage";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import middleware from "./middlewares/core/index";
import print from "vue3-print-nb";
import VueSingleSelect from "vue-single-select";
import vSelect from "vue-select";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "vue-select/dist/vue-select.css";
import titleMixin from "@/mixins/titleMixin";
import { createMetaManager } from "vue-meta";
import VueGtag from "vue-gtag";
import VueYandexMetrika from "vue3-yandex-metrika";
import VueSocketIO from "vue-3-socket.io";
import * as io from "socket.io-client";
import { Skeletor } from "vue-skeletor";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import moment from "moment-timezone";
import mitt from "mitt";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import createDirectives from "./directives/index";
import Vue3Dragscroll from "vue3-dragscroll";
import "../node_modules/vue3-emoji-picker/dist/style.css";

axios.defaults.headers.common["Content-Type"] =
	"application/json;charset=utf-8";

window.axios = axios;
window.toastr = toastr;

window.toastr.options = {
	closeButton: true,
	debug: false,
	newestOnTop: true,
	progressBar: false,
	positionClass: "toast-top-right",
	preventDuplicates: false,
	onclick: null,
	showDuration: "300",
	hideDuration: "1000",
	timeOut: "5000",
	extendedTimeOut: "1000",
	showEasing: "swing",
	hideEasing: "linear",
	showMethod: "fadeIn",
	hideMethod: "fadeOut",
};

library.add(fas);

router.beforeEach(middleware(store));

dayjs.extend(customParseFormat);

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$workbox = wb;
app.config.warnHandler = () => {};

moment.tz.guess(true);

app.config.globalProperties.$moment = moment;

app.use(store);
app.use(router);
app.use(vuetify);
app.use(Vue3Storage, {
	namespace: "pro_",
	storage: StorageType.Session,
});
const socketConnection = io.connect(
	process.env.VUE_APP_SOCKET_URL || "https://socket.ak-top.ru/"
);
app.use(
	new VueSocketIO({
		debug: true,
		connection: socketConnection,
		vuex: {
			store,
			actionPrefix: "SOCKET_",
			mutationPrefix: "SOCKET_",
		},
		cors: {
			origin: "*",
		},
	})
);
app.use(VueGtag, {
	config: {
		id: "G-5L96E7T6ZK",
	},
});
app.use(VueYandexMetrika, {
	id: "88708932",
	router: router,
	env: "production",
});
app.use(print);
app.use(PerfectScrollbar);
app.use(createMetaManager());
app.use(Vue3Dragscroll);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("vue-single-select", VueSingleSelect);
app.component("vue-select", vSelect);
app.component("vue-multiselect", Multiselect);
app.component(Skeletor.name, Skeletor);
app.mixin(titleMixin);

const directives = createDirectives(store);

Object.keys(directives).forEach((key) => {
	app.directive(key, directives[key]);
});

app.mount("#app");
