import fullAuth from "@/middlewares/fullAuth"

const Promotions = () => import(/* webpackChunkName: "promotions" */ "../../components/pages/promotion/Promotions")
const UpdatePromotion = () => import(/* webpackChunkName: "promotions" */ "../../components/pages/promotion/UpdatePromotion")

export default [
    {
        path: '/promotions',
        component: Promotions,
        name: 'promotions',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/promotions/:id',
        component: UpdatePromotion,
        name: 'edit-promotion',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
    {
        path: '/promotions/add',
        component: UpdatePromotion,
        name: 'add-promotion',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    },
]