const SettingsTab = Object.freeze({
	BASIC: "Basic",
	ORGANIZATION_REQUISITES: "OrganizationRequisites",
	SITE_REQUISITES: "SiteRequisites",
	HALLS: "Halls",
	TABLES: "Tables",
	TERMINALS: "Terminals",
	COMPANY: "Company",
	EQUIPMENT: "Equipment",
	STATIONS: "Stations",
	STORAGIES: "Storagies",
	USER: "User",
	DELIVERY: "Delivery",
	PROMOTIONS: "Promotions",
});

export default SettingsTab;
