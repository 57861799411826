export default {
	currentInstance: (state) => state.currentInstance,

	apiUrl: (state) => state.apiUrl,
	token: (state) => state.token,
	pinToken: (state) => state.pinToken,
	user: (state) => state.user,
	userPermissions: (state) => (state.user ? state.user.permissions : []),
	userRoles: (state) => (state.user ? state.user.roles : []),
	company: (state) => state.company,
	companyAssets: (state) => state.companyAssets,
	organizationRequisites: (state) => state.organizationRequisites,
	internetConnected: (state) => state.internetConnected,
	locale: (state) => state.locale,
	translations: (state) => state.translations,
	permissionTranslations: (state) => state.permissionTranslations,
	halls: (state) => state.halls,
	tables: (state) => state.tables,
	banks: (state) => state.banks,
	terminals: (state) => state.terminals,
	equipment: (state) => state.equipment,

	categoriesAndSaleables: (state) => state.categoriesAndSaleables,
	carPartsCategoriesAndSaleables: (state) =>
		state.carPartsCategoriesAndSaleables,

	searchResultProducts: (state) => state.searchResultProducts,

	//Rs

	sellerInvoices: (state) => state.invoices.seller,
	buyerInvoices: (state) => state.invoices.buyer,
	newInvoice: (state) => state.newInvoice,

	// Orders
	orderHall: (state) => state.orderHall,
	orderTable: (state) => state.orderTable,
	order: (state) => state.order,
	orderSaleables: (state) => state.orderSaleables,
	orders: (state) => state.orders,
	paginatedOrders: (state) => state.paginatedOrders,
	orderPaymentCashAmount: (state) => state.orderPaymentCashAmount,
	orderPaymentCardAmount: (state) => state.orderPaymentCardAmount,
	orderPaymentChangeAmount: (state) => state.orderPaymentChangeAmount,
	orderPaymentTipAmount: (state) => state.orderPaymentTipAmount,
	orderPaymentCalculatorType: (state) => state.orderPaymentCalculatorType,
	orderClient: (state) => state.orderClient,
	orderPromotion: (state) => state.orderPromotion,
	orderPersonsCount: (state) => state.orderPersonsCount,
	orderDiscount: (state) => state.orderDiscount,
	sendOrderToStationsDialog: (state) => state.sendOrderToStationsDialog,
	orderDeliveryType: (state) => state.orderDeliveryType,

	// Supply
	supplies: (state) => state.supplies,
	employees: (state) => state.employees,
	categories: (state) => state.categories,
	mediaFileIdsInCategoryCreate: (state) => state.mediaFileIdsInCategoryCreate,
	catalogCategories: (state) => state.catalogCategories,
	suppliers: (state) => state.suppliers,
	storages: (state) => state.storages,
	lastMonthSupplyProducts: (state) => state.lastMonthSupplyProducts,
	allProducts: (state) => state.allProducts,
	allProductsForProductsTable: (state) => state.allProductsForProductsTable,
	allCatalogProductsForProductsTable: (state) =>
		state.allCatalogProductsForProductsTable,
	productCachedList: (state) => state.productCachedList,
	products: (state) => state.products,
	productsVerified: (state) => state.productsVerified,
	productsFetched: (state) => state.productsFetched,
	productsLength: (state) => state.productsLength,
	units: (state) => state.units,
	supplyTab: (state) => state.supplyTab,
	supplyData: (state) => state.supplyData,
	selectedSupply: (state) => state.selectedSupply,
	supplyEvaluated: (state) => state.supplyEvaluated,
	supplyFirstStepProducts: (state) => state.supplyFirstStepProducts,
	supplyStep3Key: (state) => state.supplyStep3Key,
	supplyStep2Key: (state) => state.supplyStep2Key,
	branches: (state) => state.branches,
	cashflows: (state) => state.cashflows,
	legalTypes: (state) => state.legalTypes,
	allCategories: (state) => state.allCategories,
	categoriesVerified: (state) => state.categoriesVerified,
	categorySelectsTree: (state) => state.categorySelectsTree,
	categoryForm: (state) => state.categoryForm,
	ingredientCategoryOpen: (state) => state.ingredientCategoryOpen,
	ingredientCategories: (state) => state.ingredientCategories,
	productList: (state) => state.productList,
	productTreeList: (state) => state.productTreeList,
	ingredients: (state) => state.ingredients,
	categoryTree: (state) => state.categoryTree,
	ingredientProducts: (state) => state.ingredientProducts,
	inventoriesData: (state) => state.inventoriesData,
	productCreateData: (state) => state.productCreateData,
	productCreateTab: (state) => state.productCreateTab,
	editCategory: (state) => state.editCategory,
	zoomedCategory: (state) => state.zoomedCategory,
	categoryCreateEvent: (state) => state.categoryCreateEvent,
	productListView: (state) => state.productListView,
	stations: (state) => state.stations,
	selectedStation: (state) => state.selectedStation,
	selectedStationTabId: (state) => state.selectedStationTabId,
	withoutStationProductsCount: (state) => state.withoutStationProductsCount,
	station: (state) => state.station,
	supplyProductsData: (state) => state.supplyProductsData,
	productMode: (state) => state.productMode,
	editProduct: (state) => state.editProduct,
	productCanvasCondition: (state) => state.productCanvasCondition,
	productFilters: (state) => state.productFilters,
	siteRequisites: (state) => state.siteRequisites,
	companyRequisites: (state) => state.companyRequisites,
	productQueryParams: (state) => state.productQueryParams,
	supplyFilters: (state) => state.supplyFilters,
	productQuery: (state) => state.productQuery,
	productDisabledMode: (state) => state.productDisabledMode,
	productPriceAlert: (state) => state.productPriceAlert,
	realetedProductsInProductCreate: (state) =>
		state.realetedProductsInProductCreate,
	productSorting: (state) => state.productSorting,
	productMultiLanguage: (state) => state.productMultiLanguage,
	supplySteps: (state) => state.supplySteps,
	productFiltersInSupplyCreate: (state) => state.productFiltersInSupplyCreate,
	supplyDisabledSteps: (state) => state.supplyDisabledSteps,
	selectedProductIdInSupplyCreate: (state) =>
		state.selectedProductIdInSupplyCreate,
	userSettings: (state) => state.userSettings,
	settings: (state) => state.settings,
	productListLastPage: (state) => state.productListLastPage,
	productTreeListLastPage: (state) => state.productTreeListLastPage,

	// Menu
	menuCompany: (state) => state.menuCompany,
	menuCompanyCategoriesAndProducts: (state) =>
		state.menuCompanyCategoriesAndProducts,
	menuCompanyRequisites: (state) => state.menuCompanyRequisites,
	menuCatalogCategories: (state) => state.menuCatalogCategories,
	menuCatalogCategoryProducts: (state) => state.menuCatalogCategoryProducts,
	menuSelectedCatalogCategory: (state) => state.menuSelectedCatalogCategory,
	menuSelectedCatalogProduct: (state) => state.menuSelectedCatalogProduct,

	// Promotions
	promotions: (state) => state.promotions,

	// Contacts
	contacts: (state) => state.contacts,
	contactsData: (state) => state.contactsData,

	// Clients
	clients: (state) => state.clients,
	clientsTableList: (state) => state.clientsTableList,

	// Dialogs
	customPersonsQuantityDialog: (state) => state.customPersonsQuantityDialog,

	// Counters
	counters: (state) => state.counters,
	listCounters: (state) => state.listCounters,

	// Loaders
	loaders: (state) => state.loaders,

	availableLocales: (state) => state.availableLocales,
	availableTimezones: (state) => state.availableTimezones,
	availableCurrencies: (state) => state.availableCurrencies,

	currency: (state) => {
		if (!state.settings || !state.settings.length) {
			return null;
		}

		let currencyData = state.settings.find(
			(setting) => setting.key === "CURRENCY"
		);

		if (!currencyData) {
			return null;
		}

		return currencyData.value;
	},

	defaultLocale: (state) => {
		if (!state.settings || !state.settings.length) {
			return null;
		}

		let defaultLocaleData = state.settings.find(
			(setting) => setting.key === "LOCALE"
		);

		if (!defaultLocaleData) {
			return null;
		}

		return defaultLocaleData.value;
	},

	orderPrintCheck: (state) => state.orderPrintCheck,

	selectedCategoryPath: (state) => state.selectedCategoryPath,

	addClientPopup: (state) => state.addClientPopup,
	clientPopupSender: (state) => state.clientPopupSender,
	orderClientsPopup: (state) => state.orderClientsPopup,
	orderFilterClientPopup: (state) => state.orderFilterClientPopup,
	reservationClientPopup: (state) => state.reservationClientPopup,

	productModificationsStepper: (state) => state.productModificationsStepper,

	productAnimation: (state) => state.productAnimation,
	reservations: (state) => state.reservations,
	reservationsPagination: (state) => state.reservationsPagination,
	reservationEditMode: (state) => state.reservationEditMode,
	reservationData: (state) => state.reservationData,
	reservationsLoading: (state) => state.reservationsLoading,
	reservationFilterClientsPopup: (state) => state.reservationFilterClientsPopup,
	reservationFilters: (state) => state.reservationFilters,
	reservationsCount: (state) => state.reservationsCount,
	timePicker: (state) => state.timePicker,
	timePickerHH: (state) => state.timePicker.hh,
	timePickerMM: (state) => state.timePicker.mm,
	createOrderPopup: (state) => state.createOrderPopup,
	createOrderPopupReservationId: (state) => state.createOrderPopupReservationId,
	ordersLoaded: (state) => state.ordersLoaded,
	orderFilters: (state) => state.orderFilters,
	changeTablePopup: (state) => state.changeTablePopup,
	basePermissions: (state) => state.basePermissions,
	baseRoles: (state) => state.baseRoles,
	employeeData: (state) => state.employeeData,
	promotionData: (state) => state.promotionData,
	productCanvasLoader: (state) => state.productCanvasLoader,
	highlightedOrderId: (state) => state.highlightedOrderId,
	categoryData: (state) => state.categoryData,
	billingInvoices: (state) => state.billingInvoices,
	settingsTab: (state) => state.settingsTab,
	inventoryMode: (state) => state.inventoryMode,
	billingData: (state) => state.billingData,
	orderPaymentPersonsCountTitle: (state) => state.orderPaymentPersonsCountTitle,
	reservationPersonsCountDialog: (state) => state.reservationPersonsCountDialog,
	subscriptionData: (state) => state.subscriptionData,
	changePricingPlanPopup: (state) => state.changePricingPlanPopup,
	companySubscriptionInvoices: (state) => state.companySubscriptionInvoices,
	userAddWorkingScheduleData: (state) => state.userAddWorkingScheduleData,
	userWorkingSchedule: (state) => state.userWorkingSchedule,
	usersWorkScheduleFilters: (state) => state.usersWorkScheduleFilters,
	workAttendance: (state) => state.workAttendance,
	workAttendanceStats: (state) => state.workAttendanceStats,
	carPartsCategoryData: (state) => state.carPartsCategoryData,
	carPartsCategories: (state) => state.carPartsCategories,
	carPartCreateData: (state) => state.carPartCreateData,
	carMarks: (state) => state.carMarks,
	carModels: (state) => state.carModels,
	carModifications: (state) => state.carModifications,
	carGenerations: (state) => state.carGenerations,
	carParts: (state) => state.carParts,
	supplierData: (state) => state.supplierData,
	supplierEditMode: (state) => state.supplierEditMode,
	supplierIdToDelete: (state) => state.supplierIdToDelete,
	showDeleteSupplierDialog: (state) => state.showDeleteSupplierDialog,
	promotionClientsPopup: (state) => state.promotionClientsPopup,

	isCarPartsPlatform: (state) => {
		if (!state.company) return false;

		const translations = state.company.translations;
		const translation = translations.find(
			(translation) => translation.locale === "en"
		);

		if (!translation) return false;

		return (
			(translation.object_type || "").toLowerCase().replace(" ", "_") ===
			"car_parts"
		);
	},

	saleables: (state) => state.saleables,
	startWorkingDialog: (state) => state.startWorkingDialog,
	finishWorkingDialog: (state) => state.finishWorkingDialog,
	currentAttendanceRule: (state) => state.currentAttendanceRule,
	upcomingAttendanceHoursForToday: (state) =>
		state.upcomingAttendanceHoursForToday,
	attendanceForTomorrow: (state) => state.attendanceForTomorrow,
	attendanceForYesterday: (state) => state.attendanceForYesterday,
	attendanceForToday: (state) => state.attendanceForToday,
	paginatedProducts: (state) => state.paginatedProducts,
	paginatedCarParts: (state) => state.paginatedCarParts,
	incomeRequestsView: (state) => state.incomeRequestsView,
	getNameRequisites: (state) => state.posRequisitesData.nameRequisites,
	getPhoneRequisites: (state) => state.posRequisitesData.phoneRequisites,
};
