export const userDetailsDefaultValue = {
	registration_city: null,
	registration_address: null,
	registration_block: null,
	registration_entrance: null,
	registration_floor: null,
	registration_apartment: null,
	registration_postal_code: null,
	registration_intercom: null,
	actual_city: null,
	actual_address: null,
	actual_block: null,
	actual_entrance: null,
	actual_floor: null,
	actual_apartment: null,
	actual_postal_code: null,
	actual_intercom: null,
	temporary_registration: false,
	not_same_as_registration: false,
	not_employed: false,
	official_salary: null,
	organization_name: null,
	organization_identity: null,
	working_position: null,
	working_duration: null,
	working_phone_number: null,
	monthly_income: null,
	id_number: null,
	id_serie: null,
	id_document_number: null,
	id_code: null,
	id_issued_by: null,
	id_issued_date: null,
	id_valid_until: null,
	has_drivers_license: false,
	drivers_license_number: null,
	drivers_license_serie: null,
	drivers_license_issued_by: null,
	drivers_license_issued_date: null,
	drivers_license_valid_until: null,
	drivers_license_category: null,
	marital_status: null,
	has_children: false,
	children_count: null,
	partner_working: false,
	partner_full_name: null,
	partner_phone_number: null,
	partner_birth_date: null,
};
