import { createStore } from "vuex";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

window.localStorage.setItem("defaultState", JSON.stringify(state));

export default createStore({
	state,
	mutations,
	actions,
	getters,
});
