import fullAuth from "@/middlewares/fullAuth"

const VideoControl = () => import(/* webpackChunkName: "videocontrol" */ "../../components/pages/VideoControl")

export default [
    {
        path: '/videocontrol',
        component: VideoControl,
        name: 'videocontrol',
        meta: {
            layout: 'PosLayout',
            middleware: [fullAuth],
        }
    }
]
